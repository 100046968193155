import { enableClientInputs } from './add-file';

export function getClientEmailInputs() {
    return $('#addClientForm input[data-id^="email."]');
}

export function enableAllClientsInputs() {
    var emailInputs = getClientEmailInputs();

    $.each(emailInputs, function(idx, clientEmailInput) {
        var clientElement = $(clientEmailInput).parents('.client_contact_container');
        enableClientInputs(clientElement);
    });
}

//@TODO move this spaghetti jquery to a shared file, or better yet, just
// move this whole form to Vue.
export function verifyAllClientsHaveRoles(formSelector: JQuery.Selector) {
    const containerSelector = '.client_contact_container';
    const clientContainerElementList = $(formSelector).find(containerSelector);

    let shouldThrowError = false;

    // iterate through our client containers, verify that all have 
    // a role selected.
    clientContainerElementList.each(function(index, element) {
        const roleElement = $(element).find('.client-role:checked');
        if (!roleElement.length) {
            shouldThrowError = true;
            // show error message
            // and add event listener to remove error message
            $(element).find('.form-control-error-role').text('Please select a role');
            $(element).find('.client-role').on('change', function() {
                $(element).find('.form-control-error-role').text('');
            });
        }
    });
    if (shouldThrowError) {
        throw new Error('Please ensure all clients are properly configured.');
    }
}
