<template>
    <VerificationSubsection
        title="Geolocation"
        :indentation-level="1"
    >
        <GoogleMap
            class="w-full h-56"
            v-if="reportMapLocation.lat && reportMapLocation.lng"
            :map-center="reportMapLocation"
            :markerCoordinates="[reportMapLocation]"
        />

        <VerificationGrid :indentation-level="1">
            <CheckTextualResult
                label="IP Address:"
                :value="ipAnalysis.geolocation.ip"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_IP_ADDRESS_LOCATION)"
            />

            <CheckTextualResult
                label="Hostname:"
                :value="ipAnalysis.geolocation.hostname"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_IP_HOSTNAME)"
            />

            <CheckTextualResult
                label="City:"
                :value="ipAnalysis.geolocation.city"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_IP_CITY)"
            />

            <CheckTextualResult
                label="Region:"
                :value="ipAnalysis.geolocation.region"
                v-tooltip.left="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_IP_REGION)"
            />

            <CheckTextualResult
                label="Country:"
                :value="ipAnalysis.geolocation.country"
                v-tooltip.left="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_IP_COUNTRY)"
            />

            <CheckTextualResult
                label="Location:"
                :value="ipAnalysis.geolocation.location"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_IP_ADDRESS_LOCATION)"
            />
        </VerificationGrid>
    </VerificationSubsection>
    <VerificationSubsection
        title="Privacy"
        :indentation-level="1"
    >
        <VerificationGrid :indentation-level="1">
            <CheckTextualResult
                label="VPN:"
                :value="ipAnalysis.privacy.vpn"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_PRIVACY_VPN)"
            />

            <CheckTextualResult
                label="Proxy:"
                :value="ipAnalysis.privacy.proxy"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_PRIVACY_PROXY)"
            />

            <CheckTextualResult
                label="Tor:"
                :value="ipAnalysis.privacy.tor"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_PRIVACY_TOR)"
            />

            <CheckTextualResult
                label="Relay:"
                :value="ipAnalysis.privacy.relay"
                v-tooltip.left="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_PRIVACY_RELAY)"
            />

            <CheckTextualResult
                label="Hosting:"
                :value="ipAnalysis.privacy.hosting"
            />
        </VerificationGrid>
    </VerificationSubsection>
    <VerificationSubsection
        title="Network Service"
        :indentation-level="1"
    >
        <VerificationGrid :indentation-level="1">
            <CheckTextualResult
                label="Name:"
                :value="ipAnalysis.network.name"
            />

            <CheckTextualResult
                label="Domain:"
                :value="ipAnalysis.network.domain"
            />

            <CheckTextualResult
                label="Type:"
                :value="ipAnalysis.network.type"
            />
        </VerificationGrid>
    </VerificationSubsection>
</template>

<script lang="ts" setup>
import { IPAddressAnalysis } from '@/api/interfaces/identity-verification-report.api';
import { computed } from 'vue';
import GoogleMap from '../../Map/GoogleMap.vue';
import CheckTextualResult from '../common/CheckTextualResult.vue';
import VerificationGrid from '../common/VerificationGrid.vue';
import VerificationSubsection from '../common/VerificationSubsection.vue';
import { GEN_IDV_STYLED_TOOLTIP } from '../common/idv-helper-tooltip.pt';
import {
  IDV_HELPER_IP_ADDRESS_LOCATION,
  IDV_HELPER_IP_CITY,
  IDV_HELPER_IP_COUNTRY,
  IDV_HELPER_IP_HOSTNAME,
  IDV_HELPER_IP_REGION,
  IDV_HELPER_PRIVACY_PROXY,
  IDV_HELPER_PRIVACY_RELAY,
  IDV_HELPER_PRIVACY_TOR,
  IDV_HELPER_PRIVACY_VPN,
} from '../idv-static-helper-text';

const props = defineProps<{
    ipAnalysis: IPAddressAnalysis;
}>();
const reportMapLocation = computed(() => ({
    lat: props.ipAnalysis.geolocation.lat,
    lng: props.ipAnalysis.geolocation.lng,
}));
</script>
