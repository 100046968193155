<template>
    <img
        :src
        v-bind="imgAttrs"
        v-if="loaded"
        @error="loaded = false"
    />
    <slot
        v-else
        name="fallback"
    >
    </slot>
</template>

<script lang="ts" setup>
import type { ImgHTMLAttributes } from 'vue';
import { ref } from 'vue';

/**
 * This is a very general image component with a fallback slot in case it can't load.
 * Avoid business logic here
 */
const props = defineProps<{ src: ImgHTMLAttributes['src']; imgAttrs?: Omit<ImgHTMLAttributes, 'src'> }>();
defineSlots<{
    fallback: () => void;
}>();
const loaded = ref(!!props.src);
</script>
