import { useCompanyApi } from '@/api/client/api-client';
import { CheckResult, CheckResultState, IdentityVerificationResult } from '@/api/interfaces/identity-verification-report.api';

export function useIdentityVerificationReportService() {
    const companyApiClient = useCompanyApi();

    function changeStateToNotApplicable(checkResult: CheckResult): CheckResult {
        return {
            ...checkResult,
            state: CheckResultState.NotApplicable,
            stateText: 'Check Not Available',
        };
    }
    function generateNotAvailableCheck(errorMessage: string): CheckResult {
        return {
            state: CheckResultState.NotFound,
            stateText: 'Not Available',
            text: 'Not Available',
            infoList: [errorMessage],
        };
    }

    function applyAamvaOverwride(identityVerificationResult: IdentityVerificationResult) {
        if (identityVerificationResult.pdfMapping.govIdAnalysis.aamvaDisabledState) {
            const govIdAnalysis = identityVerificationResult.pdfMapping.govIdAnalysis;

            return {
                ...identityVerificationResult,
                pdfMapping: {
                    ...identityVerificationResult.pdfMapping,
                    govIdAnalysis: {
                        ...govIdAnalysis,
                        dmvIdMatch: changeStateToNotApplicable(govIdAnalysis.dmvIdMatch),
                        idNumber: changeStateToNotApplicable(govIdAnalysis.idNumber),
                        fullName: changeStateToNotApplicable(govIdAnalysis.fullName),
                        dateOfBirth: changeStateToNotApplicable(govIdAnalysis.dateOfBirth),
                        currentAddress: changeStateToNotApplicable(govIdAnalysis.currentAddress),
                        expiryDate: changeStateToNotApplicable(govIdAnalysis.expiryDate),
                        issueDate: changeStateToNotApplicable(govIdAnalysis.issueDate),
                    },
                },
            } as IdentityVerificationResult;
        }

        return identityVerificationResult;
    }

    function applySupportedChecksOverwride(identityVerificationResult: IdentityVerificationResult): IdentityVerificationResult {
        const {
            supported_checks: { address, birthdate, email_address, name, national_id, phone_number },
        } = identityVerificationResult;

        const knownYourCustomer = identityVerificationResult.pdfMapping.knownYourCustomer;
        const kycUnavailable = !address && !birthdate && !email_address && !name && !national_id && !phone_number;
        const overallKnowYourCustomer = kycUnavailable
            ? generateNotAvailableCheck('A KYC check is not available for this country')
            : identityVerificationResult.pdfMapping.overallResult.knownYourCustomer;
        const overwrittenIdentityVerificationResult: IdentityVerificationResult = {
            ...identityVerificationResult,
            pdfMapping: {
                ...identityVerificationResult.pdfMapping,
                overallResult: {
                    ...identityVerificationResult.pdfMapping.overallResult,
                    knownYourCustomer: overallKnowYourCustomer,
                },
                knownYourCustomer: {
                    ...knownYourCustomer,
                    phone: phone_number
                        ? knownYourCustomer.phone
                        : generateNotAvailableCheck('A phone number check is not available for this country.'),
                    dateOfBirth: birthdate
                        ? knownYourCustomer.dateOfBirth
                        : generateNotAvailableCheck('A Date of Birth check is not available for this country.'),
                    // note: old implementation also checked for national id and birtdate but the changed paths were unused
                },
            },
        };
        return overwrittenIdentityVerificationResult;
    }

    function mutateReport(identityVerificationResult: IdentityVerificationResult) {
        return applyAamvaOverwride(applySupportedChecksOverwride(identityVerificationResult));
    }

    async function loadIdvReport(id: IdentityVerificationResult['id']) {
        const { data, error } = await companyApiClient('/user/home/id-verification/' + id)
            .get()
            .json<IdentityVerificationResult>();

        if (error.value) {
            throw error.value;
        }
        if (data) {
            return data.value;
        }
    }

    return {
        mutateReport,
        loadIdvReport,
    };
}
