import dayjs from 'dayjs';

export const API_DATE_FORMAT = 'YYYY-MM-DD hh:mm:ss';
export const MONTH_DAY_YEAR_FORMAT = 'MM/DD/YYYY';

export function formatDate(date: dayjs.ConfigType, format: string = MONTH_DAY_YEAR_FORMAT, fallback = ''): string {
    if (!date) return fallback;
    const dateParsed = dayjs(date);
    if (dateParsed.isValid()) {
        return dateParsed.format(format);
    }
    return fallback;
}
