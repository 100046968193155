<template>
<Modal
    v-model:visible="showDeleteModal"
    footer-class="justify-end"
    title="Delete EMD"
    fullscreen-on-mobile
    :event-to-close-function="() => $emit('cancel')"
>    
    <p v-if="error" class="bg-rose-100 text-danger py-4 px-6 rounded-md text-base">
        There was an error deleting this record. Please try again, or contact support if you continue to experience the problem.
    </p>

    <div class="grid grid-cols-2 gap-y-2">
        <span>Property Address</span>
        <span class="font-hind-open-sans">{{ earlyDeposit?.address }}</span>

        <span>Clients</span>
        <ul class="font-hind-open-sans">
            <li class="list-none text-base" v-for="client in earlyDeposit?.clients">{{ client.email }}</li>
        </ul>

        <span>Deposit Amount</span>
        <span class="font-hind-open-sans">{{ formatMoney((earlyDeposit?.deposit_amount ?? 0) / 100) }}</span>
    </div>

    <template #footer>
        <button class="rounded-md py-4 px-6 bg-danger text-white" @click="confirmDelete" :disabled="!earlyDeposit">Confirm</button>
        <button class="rounded-md py-4 px-6" @click="$emit('cancel')">Cancel</button>
    </template>
</Modal>
</template>

<script setup lang="ts">
import type { EarlyDeposit } from '@/api/interfaces/early-deposit-types';
import Modal from '@/components/Components/Modal/Index.vue';
import { useEditEarlyDeposit } from '@/services/early-deposit.service';
import { formatMoney } from '@/shared/utils/format-number';
import { ref, watch } from 'vue';

const showDeleteModal = ref(false);
const props = defineProps<{ earlyDeposit?: EarlyDeposit }>();
const emit = defineEmits<{
    'cancel': [],
    'emd-deleted': [operation: 'delete', emd: Pick<EarlyDeposit, 'id'>],
}>();

const { error, response, statusCode, deleteEarlyDeposit } = useEditEarlyDeposit();

const confirmDelete = async () => {
    if (!props.earlyDeposit) return;

    await deleteEarlyDeposit(props.earlyDeposit.id);
    if (error.value) return;

    // toastr.success('Record deleted', 'Success!');
    emit('emd-deleted', 'delete', { id: props.earlyDeposit.id });
}

watch(() => props.earlyDeposit, (rowData) => {
    showDeleteModal.value = !!rowData;
})

watch(error, (error) => {
    if (error) {
        const responseJSON = JSON.stringify(response.value?.json(), undefined, 2);
        window.Bugsnag?.notify({
            errorClass: error,
            errorMessage: `EMD could not be deleted. Server response (code: ${statusCode}):\n${responseJSON}`,
        })
    }
})
</script>

<style scoped>
.font-hind-open-sans {
    font-family: Hind, 'Open Sans', Arial, serif;
}
</style>
