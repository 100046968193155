<template>
    <div>
        <div v-if="show === 'login'">
            <h1 class="mt-14 mb-1.5">Welcome!</h1>
            <p class="mb-4 text-slate-500 text-sm">
                {{ titleName }} uses {{ appName }} as a secure portal for your real estate transaction to easily share information
                with you.
            </p>
        </div>
        <div v-if="show === 'login'">
            <div class="my-3">
                <div class="text-slate-800 mb-1">Email Address</div>
                <input
                    type="text"
                    class="form-input w-full rounded"
                    :class="emailError ? 'border-2 border-rose-600' : ''"
                    v-model="loginForm.email"
                    @blur="validateEmail"
                />
                <span
                    v-if="emailError"
                    class="text-rose-600"
                    >Please enter a valid email address</span
                >
                <span
                    v-if="error"
                    class="text-rose-600"
                    >We were unable to locate this account</span
                >
            </div>
            <div class="my-3">
                <input
                    type="checkbox"
                    name="tos"
                    id="tos"
                    class="form-checkbox mr-2 rounded"
                    v-model="loginForm.tos"
                />
                <span class="text-slate-500 text-sm"
                    >I agree to <a :href="termsLink">Terms of Service</a> and <a :href="privacyLink">Privacy Policy</a></span
                >
            </div>
            <div class="my-3">
                <input
                    type="checkbox"
                    name="rememberDevice"
                    id="rememberDevice"
                    class="form-checkbox mr-2 rounded"
                    v-model="loginForm.rememberMe"
                />
                <span class="text-slate-500 text-sm">Remember this device for 30 days</span>
            </div>
            <div class="my-10">
                <button
                    type="button"
                    class="block w-full text-center bg-brand-primary px-4 py-2 font-medium text-white text-uppercase rounded"
                    :class="
                        !loginForm.tos || emailError || !loginForm.email
                            ? 'opacity-40'
                            : 'hover:bg-brand-primary-hover-focus focus:outline-none focus:text-primary-500 focus:ring-2 focus:ring-brand-primary-hover-focus focus:ring-offset-2'
                    "
                    :disabled="!loginForm.tos || emailError || !loginForm.email"
                    @click="submitAction"
                >
                    {{ submitText }}
                </button>
            </div>
        </div>
        <email-verification-form
            v-if="show === 'verification'"
            @verificationCodeValid="haveVerificationCode"
        >
            >
        </email-verification-form>
        <twofa-form
            v-if="show === 'passcode'"
            :phone-number="twoFANumber"
            :client-phone-type="phoneType"
            @passcodeSent="ampTrack2FADeliveryMethod"
            @passcodeValid="havePasscode"
        >
        </twofa-form>
        <div v-if="show === 'passcode' || show === 'verification'">
            <hr class="text-slate-200" />
            <p class="text-xs text-slate-500 mt-3">Having issues? Please contact {{ titleName }}</p>
        </div>
        <div v-else>
            <hr class="text-slate-200" />
            <p class="text-xs text-slate-600 mt-3">
                This portal provides a secure way to send payment instructions, bank information, eSignatures, documents, etc.
                between you and {{ titleName }}. If you have any questions, please contact {{ titleName }} using independently
                verified contact information.
            </p>
            <p class="font-bold mt-4 text-xs text-slate-600">Do not trust phone numbers in emails.</p>
        </div>
    </div>
</template>

<script type="ts">
import { defineComponent } from 'vue';
import EmailVerificationComponent from './EmailVerificationComponent.vue';
import TwoFAFormComponent from './TwoFAFormComponent.vue';

export default defineComponent({
    name: 'LoginForm',
    props: {
        twoFactorAuth: {
            type: Boolean,
            default: false,
        },
        titleId: {
            type: Number,
            required: true,
        },
        titleName: {
            type: String,
            required: true,
        },
        appName: {
            type: String,
            required: true,
        },
        email: {
            type: String,
            default: '',
        },
        termsLink: {
            type: String,
            default: '',
        },
        privacyLink: {
            type: String,
            default: '',
        },
        clientPhoneType: {
            type: String,
            required: false,
        },
    },
    created() {
        // reset amplitude when the user logs out
        amplitude.reset();

        document.querySelector('[page-loading]')?.remove();
    },
    data() {
        return {
            in2FA: false,
            show: 'login',
            loginForm: {
                email: this.email,
                tos: false,
                rememberMe: false,
            },
            mfa: {
                verificationCode: {
                    need: false,
                    sent: false,
                    have: false,
                },
                passcode: {
                    need: false,
                    sent: false,
                    have: false,
                },
            },
            emailError: false,
            twoFANumber: null,
            verificationCodeSent: false,
            error: false,
            phoneType: this.clientPhoneType,
        };
    },
    computed: {
        submitText: function () {
            return this.twoFactorAuth ? 'Continue' : 'Log In';
        },
    },
    mounted() {
        if (this.loginForm.email) {
            amplitude.setUserId(this.loginForm.email);
        }
        this.datadogStartRUM(this.loginForm.email);
        this.ampInitialTrack();
    },
    methods: {
        submitAction() {
            this.ampTrackEmailLoggedIn();

            this.$http
                .post('/client/login/checkCredentials', {
                    email: this.loginForm.email,
                    terms: this.loginForm.tos,
                    title_id: this.titleId,
                })
                .then(response => {
                    this.mfa.verificationCode.need = response.data['require_verification_code'];
                    this.mfa.passcode.need = response.data['require_passcode'];
                    this.twoFANumber = response.data['phoneNumber'];
                    this.phoneType = response.data['phoneType'];
                    this.next2FA();
                })
                .catch(() => {
                    this.error = true;
                });
        },
        sendVerificationCode: function () {
            this.$http.post('/client/login/sendVerificationCode').then(response => {
                this.mfa.verificationCode.sent = true;
            });
        },
        haveVerificationCode: function () {
            this.mfa.verificationCode.have = true;
            this.next2FA();
            this.ampTrackMfaCode();
        },
        havePasscode: function (phoneType) {
            this.mfa.passcode.have = true;
            this.next2FA();
            this.ampTrack2FACode(phoneType);
        },
        next2FA() {
            this.in2FA = true;
            if (this.mfa.verificationCode.need && !this.mfa.verificationCode.have) {
                this.show = 'verification';
                this.sendVerificationCode();
            } else if (this.mfa.passcode.need && !this.mfa.passcode.have) {
                this.show = 'passcode';
            } else {
                this.login();
            }
        },
        login: function () {
            this.$http
                .post('/client/login', {
                    email: this.loginForm.email,
                    remember: this.loginForm.rememberMe,
                    terms: this.loginForm.tos,
                    title_id: this.titleId,
                })
                .then(response => {
                    this.ampTrackLogin();
                    window.location.href = response.data;
                });
        },
        validateEmail() {
            if (this.loginForm.email !== '') {
                this.emailError = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.loginForm.email);
            }
        },
        datadogStartRUM(email) {
            if (!window.DD_RUM) {
                // not enabled
                return;
            }
            if (email) {
                window.DD_RUM && window.DD_RUM.setUserProperty('email', email);
            }
            window.DD_RUM && window.DD_RUM.startSessionReplayRecording();
        },
        datadogStopRUM() {
            if (!window.DD_RUM) {
                // not enabled
                return;
            }
            window.DD_RUM.stopSessionReplayRecording();
        },
        ampTrackEmailLoggedIn() {
            let eventProps = {
                prefilled: this.loginForm.email.length > 0,
                'terms selected': this.loginForm.tos,
                'remember device': this.loginForm.rememberMe,
            };
            this.ampAddCommonProps(eventProps);
            amplitude.track('login - email populated', eventProps);
        },
        ampInitialTrack() {
            let eventProps = {
                referrer: this.show === 'login' ? 'email' : 'text',
            };
            this.ampAddCommonProps(eventProps);
            amplitude.track('login - open app', eventProps);
        },
        ampTrackMfaCode() {
            let eventProps = {
                'mfa code': this.mfa.verificationCode.need && !this.mfa.verificationCode.have ? 'email' : 'text',
            };
            this.ampAddCommonProps(eventProps);
            amplitude.track('login - mfa code populated', eventProps);
        },
        ampTrack2FADeliveryMethod(phoneType) {
            let eventProps = {
                'delivery method': phoneType == 'sms' ? 'text' : 'call',
            };
            this.ampAddCommonProps(eventProps);
            amplitude.track('login - 2FA delivery method populated', eventProps);
        },
        ampTrack2FACode(phoneType) {
            let eventProps = {
                'delivery method': phoneType == 'sms' ? 'text' : 'call',
            };
            this.ampAddCommonProps(eventProps);
            amplitude.track('login - 2FA code populated', eventProps);
        },
        ampTrackLogin() {
            let eventProps = {};
            this.ampAddCommonProps(eventProps);
            amplitude.track('login - completed', eventProps);
        },
        ampAddCommonProps(props) {
            props['portal type'] = 'client';
        },
    },
    components: {
        'twofa-form': TwoFAFormComponent,
        'email-verification-form': EmailVerificationComponent,
    },
});
</script>

<style scoped></style>
