<template>
<div class="row">
    <label class="col-3 form-label">COMPANY:</label>
    <div class="col-9">
        <div class="form-label form-group p-0">
            <select name="company_id" id="select-company" data-id="company_id" aria-label="Select company">
                <option v-for="(company) in props.companies" :value="company.id">
                    {{ company.name }}
                </option>
            </select>
            <span id="company_id_error" data-id="company_id.error" class="form-control-error-description text-danger"></span>
        </div>
    </div>
</div>
<div class="row">
    <label class="col-3 form-label">BRANCH:</label>
    <div class="col-9">
        <div class="form-label form-group p-0">
            <select name="branch_id" id="select-branch"  data-id="branch_id" aria-label="Select branch">
                <!-- {{-- Populated by jQuery --}} -->
            </select>
            <span id="branch_id_error" data-id="branch_id.error" class="form-control-error-description text-danger"></span>
        </div>
    </div>
</div>
</template>

<script setup lang="ts">
const props = defineProps<{
    companies: { id: string, name: string }[]
}>();
</script>