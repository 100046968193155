<template>
    <div>
        <button
            ref="trigger"
            :aria-expanded="dropdownOpen"
            :class="{ 'bg-slate-100 text-slate-500': dropdownOpen }"
            aria-haspopup="true"
            class="bg-white text-slate-400 hover:text-slate-500 rounded-full shadow-none"
            @click.prevent="dropdownOpen = !dropdownOpen"
        >
            <span class="sr-only">Menu</span>
            <svg
                class="w-8 h-8 fill-current"
                viewBox="0 0 32 32"
            >
                <circle
                    cx="16"
                    cy="16"
                    r="2"
                />
                <circle
                    cx="10"
                    cy="16"
                    r="2"
                />
                <circle
                    cx="22"
                    cy="16"
                    r="2"
                />
            </svg>
        </button>
        <teleport to="body">
            <transition
                enter-active-class="transition ease-out duration-200 transform"
                enter-from-class="opacity-0 -translate-y-2"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-out duration-200"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div
                    v-show="dropdownOpen"
                    ref="content"
                    class="absolute z-10 min-w-36 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
                >
                    <ul
                        ref="dropdown"
                        @focusin="dropdownOpen = true"
                        @focusout="dropdownOpen = false"
                    >
                        <slot />
                    </ul>
                </div>
            </transition>
        </teleport>
    </div>
</template>

<script>
    import { nextTick, onMounted, onUnmounted, ref, watch } from 'vue';

    export default {
        name: 'DropdownEditMenu',
        props: ['align'],
        setup() {
            const dropdownOpen = ref(false);
            const trigger = ref(null);
            const dropdown = ref(null);
            const content = ref(null);

            // close on click outside
            const clickHandler = ({ target }) => {
                if (!dropdownOpen.value || trigger.value.contains(target)) return;
                dropdownOpen.value = false;
            };

            // close if the esc key is pressed
            const keyHandler = ({ keyCode }) => {
                if (!dropdownOpen.value || keyCode !== 27) return;
                dropdownOpen.value = false;
            };
            var isItScrollableWithoutVisibleScrollbars = function (el) {
                return el && el.scrollHeight > el.offsetHeight && !(el.offsetWidth > el.scrollWidth);
            };
            onMounted(() => {
                document.addEventListener('click', clickHandler);
                document.addEventListener('keydown', keyHandler);
            });

            watch(dropdownOpen, () => {
                nextTick(() => {
                    const triggerRect = trigger.value.getBoundingClientRect();
                    const contentRect = content.value.getBoundingClientRect();
                    content.value.style.top = triggerRect.top + window.scrollY + triggerRect.height + 'px';
                    content.value.style.left = triggerRect.left + window.scrollX - contentRect.width + triggerRect.height + 'px';
                });
            });

            onUnmounted(() => {
                document.removeEventListener('click', clickHandler);
                document.removeEventListener('keydown', keyHandler);
            });

            return {
                dropdownOpen,
                trigger,
                dropdown,
                content,
            };
        },
    };
</script>
