import { useEmployeeApi } from '@/api/client/api-client';
import type { CommonApiError } from '@/api/interfaces/common-error.api';
import type { CompanyAccount } from '@/api/interfaces/company-account.api';
import type { CreatePaymentTier, PaymentTier, PaymentTierError } from '@/api/interfaces/payment-tier.api';
import { roundMoney } from '@/shared/utils/format-number';
import { useEmployeeAccountPaymentSettingsStore } from '@/stores/employee/payment-settings.store';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';

export function usePaymentTierService() {
    const apiClient = useEmployeeApi();
    const paymentSettingsStore = useEmployeeAccountPaymentSettingsStore();

    async function loadPaymentTiers(companyId: number) {
        const { data, error } = await apiClient(`/api/payments/pricingtier/${companyId}`).get().json<PaymentTier[]>();
        if (error.value) {
            return console.error(new Error('Error fetching tiers' + error));
        }
        if (data.value) {
            //** This mapping ensure numeric types in the stores avoiding number-strings */
            const mappedTierList: PaymentTier[] =
                data.value?.map(
                    apiEntry =>
                        ({
                            ...apiEntry,
                            min_payment: Number(apiEntry.min_payment),
                            max_payment: Number(apiEntry.max_payment),
                            base_fee: Number(apiEntry.base_fee),
                            variable_percentage_fee: Number(apiEntry.variable_percentage_fee) * 100,
                            variable_flat_fee: Number(apiEntry.variable_flat_fee),
                            hold_time: Number(apiEntry.hold_time),
                            fee_paid_by_account: Boolean(Number(apiEntry.fee_paid_by_account)),
                            created_at: dayjs(apiEntry.created_at).toDate(),
                            updated_at: dayjs(apiEntry.updated_at).toDate(),
                        }) as PaymentTier,
                ) ?? [];
            paymentSettingsStore.setPaymentTiers(applyMinPayment(mappedTierList));
        }
        return paymentSettingsStore.paymentTiers;
    }

    async function savePaymentTiers(companyId: CompanyAccount['id'], tiers: PaymentTier[]): Promise<{ errors?: string[] }> {
        const tierWithMinimumPayment = applyMinPayment(tiers);
        const tierPayload: CreatePaymentTier[] =
            tierWithMinimumPayment?.map(tier => ({
                min_payment: tier.min_payment || 0,
                max_payment: tier.max_payment || 0,
                base_fee: tier.base_fee || 0,
                variable_percentage_fee: (tier.variable_percentage_fee || 0) / 100,
                variable_flat_fee: tier.variable_flat_fee || 0,
                hold_time: tier.hold_time || 0,
                fee_paid_by_account: tier.fee_paid_by_account,
            })) ?? [];

        const { error, response } = await apiClient(`/api/payments/pricingtier/${companyId}`)
            .post({
                tiers: tierPayload,
            })
            .json();

        if (error.value) {
            const responseBody = (await response.value?.json()) as CommonApiError<PaymentTierError> | undefined;
            const message = responseBody?.error_message || 'Error saving tiers. Please try again in a few moments';
            return { errors: [message] };
        }
        return { errors: [] };
    }

    /** Apply the min_payment based on the max_payment */
    function applyMinPayment(tiers: PaymentTier[] = []): PaymentTier[] {
        const sortedTiers = cloneDeep(tiers || []).sort((a, b) => (a?.max_payment > b?.max_payment ? 1 : -1));
        const tiersWithMinimum = sortedTiers.map((tier, index) => {
            return index === 0
                ? { ...tier, min_payment: 0.01 }
                : { ...tier, min_payment: roundMoney(sortedTiers[index - 1].max_payment + 0.01) };
        });
        console.log({ tiersWithMinimum });
        return tiersWithMinimum;
    }

    return {
        loadPaymentTiers,
        savePaymentTiers,
        get paymentTiersList(): PaymentTier[] {
            return paymentSettingsStore.paymentTiers || [];
        },
    };
}
