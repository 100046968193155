<template>
    <div class="flex flex-col py-2 px-7 w-full">
        <div class="flex flex-row gap-6 border-b-slate-500 border-b-2 pt-4 pb-2 pl-2">
            <div class="flex-1 text-lg font-bold text-slate-800 flex flex-row align-middle gap-1">
                {{ title }}
                <div
                    v-if="helperText"
                    class="inline-block text-sm p-1"
                    v-tooltip.right="GEN_IDV_STYLED_TOOLTIP(helperText)"
                >
                    <font-awesome-icon
                        icon="fa-regular fa-circle-question "
                        size="sm"
                    ></font-awesome-icon>
                </div>
            </div>
            <div class="w-2/12 text-right">
                <CheckStateResult
                    v-if="sectionCheckResult"
                    :value="sectionCheckResult"
                    class="font-bold text-lg"
                />
            </div>
            <div
                class="text-xs w-14 text-blue-500 items-center justify-end pr-1 flex cursor-pointer"
                @click="contentExpanded = !contentExpanded"
            >
                {{ contentExpanded ? 'Collapse' : 'Expand' }}
            </div>
        </div>

        <div v-if="contentExpanded">
            <slot v-if="sectionCheckResult?.state !== CheckResultState.NotFound"></slot>
            <CheckErrorList
                :value="sectionCheckResult"
                v-if="sectionCheckResult && !omitErrorList"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { CheckResult, CheckResultState } from '@/api/interfaces/identity-verification-report.api';
import { ref } from 'vue';
import CheckErrorList from './CheckErrorList.vue';
import CheckStateResult from './CheckStateResult.vue';
import { GEN_IDV_STYLED_TOOLTIP } from './idv-helper-tooltip.pt';

const contentExpanded = ref(true);

withDefaults(defineProps<{ sectionCheckResult?: CheckResult; title: string; helperText: string; omitErrorList?: boolean }>(), {
    omitErrorList: false,
});
</script>
