<template>
    <div
        class="bg-emerald-100 px-21 py-21 w-full flex flex-row gap-2 rounded p-5"
        v-if="!isLaunchMessageHidden"
    >
        <font-awesome-icon
            icon="fa-regular fa-address-card"
            class="text-[28px]"
        />
        <div class="flex-1">
            These results empower agents to make a more informed decision regarding the client being verified.
        </div>
        <font-awesome-icon
            icon="fa-solid fa-xmark"
            class="text-[22px] opacity-50"
            @click="hideLaunchMessage = 'true'"
        />
    </div>
</template>

<script lang="ts" setup>
import { useLocalStorage } from '@vueuse/core';
import { computed } from 'vue';

const hideLaunchMessage = useLocalStorage('idv-report-launch-message-hidden', 'false');
const isLaunchMessageHidden = computed(() => hideLaunchMessage.value === 'true');
</script>
