import { SessionUser, SessionUserMeta } from '@/interfaces/SessionUser';
import { useCurrentUserStore } from '@/stores/current-user.store';

export function useCurrentUserService() {
    const currentUserStore = useCurrentUserStore();

    function loadGlobalUserOrLogout() {
        if (isRouteLogin(location.pathname)) {
            return;
        }
        try {
            loadGlobalUserData();
        } catch (e) {
            console.error('Error loading user from session. Redirecting to login', e);
            currentUserStore.setCurrentUser({
                id: 0 as number,
                email: '' as string,
                name: '' as string,
            });
            if (!isRouteLogin(location.pathname)) {
                location.href = '/login';
            }
        }
    }

    /**
     * Login page is considered true for paths ending in
     * - `/login`
     * - `/ssologin`
     */
    function isRouteLogin(path: string = ''): boolean {
        const isLoginPage = (path.match(/\/(login)$/i)?.length || 0) > 0;
        const isSSOPage = (path.match(/\/(ssologin)$/i)?.length || 0) > 0;
        const isPasswordPage = (path.match(/\/(password)$/i)?.length || 0) > 0;
        const isPasswordResetPage = path.search('password/reset') > 0;

        return isLoginPage || isSSOPage || isPasswordPage || isPasswordResetPage;
    }

    const getInitials = (user: SessionUser): string => {
        //Warning: BE resolves user data differently between  client portal uses a different name structure t
        if (user.first || user.last) {
            return `${user.first?.charAt(0)}${user.last?.charAt(0)}`.toUpperCase();
        }
        if (user.name) {
            const nameParts = user.name.split(' ') || [];
            // given a name like "John Doe", we want to get "JD"
            // max 2 initials
            // get the first and last name initials
            const initials = nameParts
                .map((part, index) => {
                    if (index === 0 || index === nameParts.length - 1) {
                        return part[0].toUpperCase();
                    }
                    return '';
                })
                .join('');

            return initials;
        }
        return '';
    };

    function loadGlobalUserData() {
        const globalUserData = window?.options?.currentUser;

        if (!globalUserData) {
            throw new Error('currentUser is missing from window.options global variable');
        }

        // used for our login bar (and possibly elsewhere)
        const initials = getInitials(globalUserData);
        currentUserStore.setCurrentUser({
            id: globalUserData.id as number,
            email: globalUserData.email as string,
            name: globalUserData.name as string,
            initials: initials as string,
        });
        currentUserStore.setCurrentUserMeta({
            hasEmployeeRole: window?.options?.userHasEmployeeRole,
            supportAccountId: Number(window?.options?.supportAccountId),
            userAccountId: Number(window?.options?.userAccountId),
            csrfToken: window?.options?.csrf_token,
        });
    }

    async function logout(logoutRoute: string = '/logout', redirect: string = '/login') {
        const csrfToken = currentUserStore.currentUserMeta?.csrfToken;
        const formData = new FormData();
        formData.set('_token', csrfToken);

        await fetch(logoutRoute, {
            method: 'POST',
            body: formData,
        });
        if (redirect) {
            window.location.href = redirect;
        }
    }

    async function requestMFACode(getCodeRoute: string, getCodeMethod: string) {
        const csrfToken = currentUserStore.currentUserMeta?.csrfToken;
        const formData = new FormData();
        formData.set('_token', csrfToken);
        formData.set('messageType', getCodeMethod);
        await fetch(getCodeRoute, {
            method: 'POST',
            body: formData,
        }).then(() => {
            window.location.href = '/user/mfa/verify';
        });
    }

    async function verifyMFACode(verifyCodeRoute: string, code: string) {
        const csrfToken = currentUserStore.currentUserMeta?.csrfToken;
        const formData = new FormData();
        formData.set('_token', csrfToken);
        formData.set('passcode', code);
        await fetch(verifyCodeRoute, {
            method: 'POST',
            body: formData,
        }).then(response => {
            window.location.href = response.url;
        });
    }

    return {
        get currentUser(): SessionUser | undefined {
            return currentUserStore.currentUser;
        },
        get currentUserMeta(): SessionUserMeta {
            return currentUserStore.currentUserMeta;
        },
        loadGlobalUserOrLogout,
        logout,
        requestMFACode,
        verifyMFACode,
    };
}
