export const HANDLED_ERROR = 'handled_error';

export function handleServerErrorResponse(
    jqXHR: JQueryXHR | Pick<JQueryXHR, 'status' | 'responseJSON'>,
    textStatus: JQuery.Ajax.ErrorTextStatus | typeof HANDLED_ERROR,
    formId: JQuery.Selector = '#addClientForm',
    scrollToError = true
) {
    if (jqXHR.status === 422) {
        // 422 = error in form input
        toastr.error('Please correct form error(s)', 'Error(s)!', {timeOut: 5000});
        const errors: Record<string, string> = jqXHR.responseJSON?.errors ?? {};
        clearFormErrors(formId);

        $.each(errors, function (key, val) {
            const inputSelector = $(formId + ' [data-id="' + key + '"]');
            const errorSelector = $(formId + ' [data-id="' + key + '.error"]');
            // For errors that have visible error elements, show error in the error element
            // Otherwise, show the error in a block (e.g. hidden email input errors)
            if (errorSelector.is(":visible") && errorSelector.length) {
                inputSelector.addClass("error");
                errorSelector.addClass("error").html(val);
                if (scrollToError) {
                    scrollToElement('.error');
                }
            } else {
                $(formId + ' #errorBlockForHiddenOrMissingElements').append("<div>" + val + "</div>");
            }
        });

        // Display separate error message for missing information for 'cm' orders
        $.each(['vendor_order_id', 'vendor_transaction_id'], function (key, val) {
            if (val in errors) {
                toastr.error(errors[val], 'Fatal Error', {timeOut: 0});
            }
        });
    }
    else if (textStatus === HANDLED_ERROR) {
        return; // no-op, the calling code will handle logging the error/updating the UI
    }
    else if (textStatus === 'timeout') {
        toastr.error('Request timed out. Please try again', 'Error!', {timeOut: 5000});
    }
    else if (jqXHR.responseJSON?.message) {
        toastr.error(jqXHR.responseJSON.message, 'Error!', {timeOut: 8000});
    }
    else {
        toastr.error('Unknown error. Please try again', 'Error!', {timeOut: 5000});
    }
}

export function clearFormErrors(formId: JQuery.Selector = '#addClientForm') {
    $('.form-control-error-description').text('');
    $(formId + ' .error').removeClass('error');
    $(formId + ' [data-id$=".error"]').text('');  // clear error messages from server
    $(formId + ' label[id$="-error"][for^="email"]').remove();
}

function scrollToElement(element: JQuery.Selector) {
    if (typeof element === 'string') {
        element += ':visible';
    }
    const $selector = $(element);
    if ($selector.length) {
        $([document.documentElement, document.body]).animate({
            scrollTop: $selector.first().offset().top - 220
        }, 700);
    }
}
