<template>
    <div class="flex flex-col py-2 w-full">
        <div class="flex flex-row gap-6 border-b-slate-200 border-b-[1px] pt-2 pb-2">
            <div
                class="flex-1 text-base font-bold text-slate-800"
                :class="{
                    'pl-2 text-base': indentationLevel === 0,
                    'pl-[18px] text-sm': indentationLevel === 1,
                }"
            >
                {{ title }}
                <div
                    class="inline-block"
                    v-if="helperText"
                    v-tooltip.right="GEN_IDV_STYLED_TOOLTIP(helperText)"
                >
                    <font-awesome-icon
                        icon="fa-regular fa-circle-question "
                        size="xs"
                    ></font-awesome-icon>
                </div>
            </div>
            <div
                class="text-xs w-14 items-center justify-end pr-1 flex cursor-pointer text-neutral-600"
                @click="contentExpanded = !contentExpanded"
            >
                <font-awesome-icon
                    icon="fa-solid fa-chevron-up"
                    v-if="contentExpanded"
                    size="sm"
                ></font-awesome-icon>
                <font-awesome-icon
                    icon="fa-solid fa-chevron-down"
                    v-else
                    size="sm"
                ></font-awesome-icon>
            </div>
        </div>

        <div
            class=""
            v-if="contentExpanded"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { GEN_IDV_STYLED_TOOLTIP } from './idv-helper-tooltip.pt';

const contentExpanded = ref(true);

withDefaults(defineProps<{ title: string; indentationLevel?: number; helperText?: string }>(), {
    indentationLevel: 0,
    helperText: '',
});
</script>
