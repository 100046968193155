import {
    IDVDependencySettings,
    IDVSettingKey,
    PartialIDVDependencySettings,
} from '@/employee-portal/pages/accounts/forms/form-payloads';

const displayIDVChanges = (existingIDVDependencySettings: PartialIDVDependencySettings, newObj: PartialIDVDependencySettings) => {
    const idvDependencyContainer = document.getElementById('account-idv-dependency-container');
    const idvDependencySpan = document.getElementById('account-idv-dependency');

    const diff: { [key: string]: { oldVal: boolean; newVal: boolean } } = {};
    Object.entries(existingIDVDependencySettings).forEach(entries => {
        const key = entries[0] as IDVSettingKey;
        const oldVal = entries[1].isIDVEnabled;
        const newVal = newObj[key]?.isIDVEnabled ?? false;
        if (oldVal !== newVal) {
            diff[key] = {
                oldVal,
                newVal,
            };
        }
    });
    if (!idvDependencyContainer || !idvDependencySpan) {
        return false;
    }
    if (Object.keys(diff).length > 0) {
        idvDependencyContainer.style.display = 'block';
        idvDependencySpan.innerHTML = '';
        Object.entries(diff).forEach(entries => {
            const key = entries[0] as IDVSettingKey;
            const newVal = entries[1].newVal;
            const newdiffSpan = document.createElement('span');
            newdiffSpan.innerHTML = `${existingIDVDependencySettings[key]?.clientLabel}:  ${newVal ? 'Enabled' : 'Disabled'}`;
            newdiffSpan.appendChild(document.createElement('br'));
            idvDependencySpan.appendChild(newdiffSpan);
        });
    } else {
        idvDependencyContainer.style.display = 'none';
    }
};

export const updateIDVSettings = (existingIDVDependencySettings: IDVDependencySettings) => {
    // iterate through our idv keys and create a new object with the new values. we can iterate over
    // the keys of the existingIDVDependencySettings object because we know that the keys are the same

    const newIDVDependencySettings: PartialIDVDependencySettings = {};

    // make an identical object to the existingIDVDependencySettings object, but with the new values, so
    // we can diff.
    Object.entries(existingIDVDependencySettings).forEach(([key, values]) => {
        // ensure that our key conforms to the IDVSettingKeys type
        const typedKey = key as IDVSettingKey;
        // just read the checkbox id from the dom. we don't need no jquery.
        const newIDVDependencyValue = !!(document.getElementById(typedKey) as HTMLInputElement)?.checked;
        newIDVDependencySettings[typedKey] = {
            clientLabel: values.clientLabel,
            isIDVEnabled: newIDVDependencyValue,
        };
    });
    // compare the old and new IDV Dependency settings and if there are any differences set
    // #account-idv-dependency-container to show
    displayIDVChanges(existingIDVDependencySettings, newIDVDependencySettings);
    // if we have an empty object, we don't need to show the container
    // update our hidden inputs with the new values
    Object.entries(newIDVDependencySettings).forEach(entries => {
        const key = entries[0];
        const isIDVEnabled = entries[1].isIDVEnabled;
        const idvHiddenEl = document.querySelector(`#editAccountModal input[name="${key}"]`);
        if (idvHiddenEl instanceof HTMLInputElement) {
            idvHiddenEl.value = `${isIDVEnabled}`;
        }
    });
};
