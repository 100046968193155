<template>
    <IdentityVerificationReviewEditor
        :idvEntryToUpdate
        @finished="finishReviewEdit"
    />
</template>

<script lang="ts" setup>
import type { IDVReviewEntry } from '@/api/interfaces/identity-verification.api';
import { BRIDGE_EVENT_EMITTER, BRIDGE_EVENTS } from '@/shared/constants/event-emitter';
import type Emittery from 'emittery';
import { inject, ref } from 'vue';
import IdentityVerificationReviewEditor from './IdentityVerificationReviewEditor.vue';

/** This is a wrapper for the IdentityVerificationReview component. It exists as an entry point
 * while we are using a vue sprinkle to instantiate a vue app in the old jquery code.
 * The handling of events from external code should live here to keep components down unaware of that.
 * It should be kept thin and not contain business logic and should be removed once
 * we have a proper SPA replacing the current file management page or equivalent
 */
const idvEntryToUpdate = ref<IDVReviewEntry['id']>();
const fileId = ref<number>();

const bridgeEventEmitter = inject<Emittery>(BRIDGE_EVENT_EMITTER);

bridgeEventEmitter?.on(BRIDGE_EVENTS.IDV.CHANGE_IDV_REVIEW_TO_UPDATE, data => {
    idvEntryToUpdate.value = undefined;
    if (!isNaN(Number(data?.id))) {
        idvEntryToUpdate.value = Number(data.id);
        fileId.value = Number(data.fileId);
    }
});

function finishReviewEdit({ updated } = { updated: boolean }) {
    bridgeEventEmitter?.emit(BRIDGE_EVENTS.IDV.CHANGE_IDV_REVIEW_TO_UPDATE_DONE, {
        fileId: fileId.value,
        idvEntryToUpdate: idvEntryToUpdate.value,
        updated,
    });
    idvEntryToUpdate.value = undefined;
}
</script>
