<template>
    <div>
        <h1>Identity Verification Review</h1>
        <Menubar
            :model="sections"
            :pt="{
                menu: 'font-inter text-xs font-semibold no-underline uppercase text-neutral-500 animate-fade-in',
            }"
        />
        <IdentityVerificationTable :label="routes[activeTab]?.label" />
    </div>
</template>

<script setup lang="ts">
import Menubar from 'primevue/menubar';
import type { MenuItem } from 'primevue/menuitem';
import { computed, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import IdentityVerificationTable from './IdentityVerificationTable.vue';
import type { Route } from './types';

interface LabelRequiredMenuItem extends MenuItem {
    label: string;
}

const route = useRoute();
const router = useRouter();

const activeTab = computed<Route>(() => (route.query.active as Route) || 'all');

const makeSection = (route: Route, label: string) => ({
    label,
    route,
    class: () => ({ 'active-menu-item': activeTab.value === route }),
    command: () => {
        updateRoute(route);
    },
});

const routes = {
    all: makeSection('all', 'All'),
    'completed-review': makeSection('completed-review', 'Completed Review'),
};

const sections: LabelRequiredMenuItem[] = Object.values(routes);

const updateRoute = (route: Route) => {
    router.push({ query: { active: route } });
};

onBeforeMount(() => {
    // if query param in url is not a key in `routes`, default to a known tab
    if (!Object.keys(routes).includes(activeTab.value)) {
        updateRoute('all');
    }
});
</script>

<style scoped></style>
