import type { CreateEarlyDepositErrorPayload, CreateEarlyDepositRequest, CreateEarlyDepositResponse, EarlyDeposit, EarlyDepositTableResponse } from '@/api/interfaces/early-deposit-types';
import { useFetch } from '@vueuse/core';
import { computed, ref } from 'vue';

type RequestInit = Parameters<typeof useFetch>[1];
type UseFetchParams = Exclude<Parameters<typeof useFetch>[2], undefined>;

export const useCreateEarlyDeposit = () => {
    const { isFetching, post } = useFetch('/user/early-deposit', { immediate: false })
        .json<CreateEarlyDepositResponse>();

    return {
        isFetchingEarlyDeposit: isFetching,
        createEarlyDeposit: (payload: CreateEarlyDepositRequest) => post(payload),
    }
}

export const useEarlyDepositsPaged = (() => {
    const pageRef = ref<number>(1);
    const getUrl = () => `/user/early-deposit/table?page=${pageRef.value}`;
    const useFetchOpts: UseFetchParams = {
        refetch: true,
        beforeFetch({ cancel }) {
            if (!window.location.pathname.includes('/user/early-deposit')) cancel();
        }
    };
    const useFetchResources = useFetch(getUrl, useFetchOpts).get().json<EarlyDepositTableResponse>();

    const earlyDepositsPaged = {
        pageRef,
        ...useFetchResources,
    };

    return () => earlyDepositsPaged;
})()

export const useEditEarlyDeposit = () => {
    const emdId = ref<string>('');

    const getUrl = computed(() => `/user/early-deposit/${emdId.value}`);
    const options: UseFetchParams = {
        immediate: false,
        beforeFetch: ({ cancel }) => {
            if (!emdId.value) cancel();
        }
    };
    const useFetchResources = useFetch(getUrl, options).json<EarlyDeposit>();

    return {
        data: useFetchResources.data,
        error: useFetchResources.error,
        isFetching: useFetchResources.isFetching,
        response: useFetchResources.response,
        statusCode: useFetchResources.statusCode,
        updateEarlyDeposit: (payload: Pick<EarlyDeposit, 'id' | 'address' | 'deposit_amount'>) => {
            emdId.value = payload.id;
            return useFetchResources.put(payload).execute();
        },
        deleteEarlyDeposit: (id: EarlyDeposit['id']) => {
            emdId.value = id;
            return useFetchResources.delete().execute();
        },
    }
}

/**
 * In an effort to speed things along for Early Deposit (EMD), the seemingly
 * similar [Create Form UI]({@link file://./../../../views/partials/user/addFile.blade.php})
 * was largely reused. Unfortuntately, the server-side error validation
 * for the Create File route returns element selectors for specific parts
 * of that page. The error response for EMD was not created when that convention
 * was known, so this function translates the EMD error response into something
 * the validation messages function of the Create File route can handle. This has
 * saved us so much time.
 */
export const makeErrorResponseCompatibleWithCreateFileValidation = async (response: Response) => {
    let data: CreateEarlyDepositErrorPayload;

    try {
        data = await response.json();
    } catch (err: any) {
        window.Bugsnag?.notify({
            errorClass: err,
            errorMessage: 'Early Deposit response could not be converted to '
        })
        return {};
    }
    
    if (!('errors' in data)) return {};

    const compatiblePayload: { errors: Partial<Record<string, string[]>> } = data;
    const digitRegex = /\d+/;
    const clientEmailRegex = /clients\.\d+\.email/g;
    const clientPhoneRegex = /clients\.\d+\.phone/g;
    const clientRoleRegex = /clients\.\d+\.role/g;
    const notifyingUsersRegex = /notifying_users\.\d+/g;

    for (const [errorKey, validationMsg] of Object.entries(data.errors)) {
        const idx = digitRegex.exec(errorKey)?.[0];
        if (!idx) continue;

        let compatibleKey = '';
        let compatibleMsg: string[] = [];

        if (clientEmailRegex.test(errorKey)) {
            compatibleKey = `email.${idx}`;
            compatibleMsg = validationMsg?.map((msg) => msg.replaceAll(clientEmailRegex, compatibleKey)) ?? [];
        } else if (clientPhoneRegex.test(errorKey)) {
            compatibleKey = `phone.${idx}`;
            compatibleMsg = validationMsg?.map((msg) => msg.replaceAll(clientPhoneRegex, compatibleKey)) ?? [];
        } else if (clientRoleRegex.test(errorKey)) {
            compatibleKey = `role.${idx}`;
            compatibleMsg = validationMsg?.map((msg) => msg.replaceAll(clientRoleRegex, compatibleKey)) ?? [];
        } else if (notifyingUsersRegex.test(errorKey)) {
            compatibleKey = `notifying_users.${idx}`;
            compatibleMsg = validationMsg?.map((msg) => msg.replaceAll(notifyingUsersRegex, compatibleKey)) ?? [];
        }

        if (compatibleKey) {
            delete data.errors[errorKey as keyof typeof data.errors]; // mutate the original data so thath any errors left behind may still be shown to the user
            compatiblePayload.errors[compatibleKey] = compatibleMsg;
        }
    }

    return compatiblePayload;
}
