import { useEmployeeApi } from '@/api/client/api-client';
import type {
  IDVEmployeeStatuses,
  IDVFilterTypes,
  IDVReviewEntriesFetchResponse,
  IDVReviewEntry,
  IdvReviewPayload,
} from '@/api/interfaces/identity-verification.api';
import { useEmployeeIdentityVerificationStore } from '@/stores/employee/identity-verification.store';

export function useIdentityVerificationService() {
    const employeeApiPrefix = '/api/id-verifications';
    const apiClient = useEmployeeApi();
    const employeeIdentityVerificationStore = useEmployeeIdentityVerificationStore();

    /**
     * Retrieves identity verification review entries from the API.
     *
     * @returns {Promise<{ data: IDVReviewEntries; error: unknown }>} A promise that resolves to an object containing the retrieved data and any error that occurred during the request.
     */
    async function fetchIdentityVerificationEntries(filterType: IDVFilterTypes) {
        // if we have a filterType, we append it to the URL as a query parameter
        const filter = filterType ? `?${filterType}=true` : '';
        // check if we have a json signature on the get method
        const { data, error } = await apiClient(`${employeeApiPrefix}${filter}`).get().json<IDVReviewEntriesFetchResponse>();
        if (error.value) {
            throw new Error('Error fetching identity verification entries' + error.value);
        }
        if (data.value?.data) {
            employeeIdentityVerificationStore.setIdvReviewEntries(data.value.data);
        }
        return data;
    }

    async function employeeUpdateIdentityVerificationEntry(
        entryId: IDVReviewEntry['id'],
        payload: IdvReviewPayload & { status: IDVEmployeeStatuses },
    ) {
        await updateIdentityVerificationEntry(entryId, payload, 'employee');
        const existingEntry = employeeIdentityVerificationStore.idvReviewEntries.find(e => e.id === entryId);
        if (existingEntry) {
            existingEntry.status = payload.status;
            employeeIdentityVerificationStore.updateIdvReviewEntry(existingEntry);
        }
    }

    async function updateIdentityVerificationEntry(
        entryId: IDVReviewEntry['id'],
        payload: IdvReviewPayload,
        context: 'admin' | 'employee',
    ): Promise<void> {
        const path =
            context === 'admin' ? `/user/home/id-verifications/${entryId}/reviews` : `${employeeApiPrefix}/${entryId}/reviews`;

        const { error } = await apiClient(path).post(payload).json<IDVReviewEntry>();
        if (error.value) {
            throw new Error('Error updating identity verification entry' + error.value);
        }
    }

    function getDownloadUrl(identityVerificationId: number) {
        return `${employeeApiPrefix}/${identityVerificationId}/download`;
    }

    return {
        fetchIdentityVerificationEntries,
        updateIdentityVerificationEntry,
        employeeUpdateIdentityVerificationEntry,
        getDownloadUrl,
        get idvReviewEntries() {
            return employeeIdentityVerificationStore.idvReviewEntries;
        },
    };
}
