<template>
<Paginator
    :rows="$props.per_page"
    :total-records="$props.total"
    :first="zeroIndexedFrom"
    :last="zeroIndexedTo"
    @page="goToPage"
/>
<p class="text-center from-to-total">{{$props.from}} to {{$props.to}} of {{$props.total}}</p>
</template>

<script setup lang="ts">
import { EarlyDepositTableResponse } from '@/api/interfaces/early-deposit-types';
import Paginator, { PageState } from 'primevue/paginator';
import { computed } from 'vue';

const props = defineProps<EarlyDepositTableResponse['meta']>();
const emit = defineEmits<{ page: [page: number] }>();
const model = defineModel<EarlyDepositTableResponse['meta']['current_page']>({ required: true })
const zeroIndexedFrom = computed(() => props.from - 1);
const zeroIndexedTo = computed(() => props.to - 1);

const goToPage = ({ page }: PageState) => {
    model.value = page + 1;
    emit('page', page + 1);
}
</script>

<style scoped>
.from-to-total {
    word-spacing: 0.2ch;
}
</style>
