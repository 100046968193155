<template>
    <Dialog
        v-model:visible="dialogOpen"
        modal
        position="center"
        :draggable="false"
        :dismissableMask="true"
        :closable="true"
        :close-on-escape="true"
        :showHeader="false"
        :block-scroll="false"
        :pt="{
            root: 'p-0 max-h-[98vh] h-[98vh] xs:w-full lg:max-w-7xl max-w-7xl rounded-t-lg overflow-hidden',
            content: 'pl-0 pr-0 overflow-hidden',
            header: 'h-0 p-1',
        }"
        :close-icon="' '"
        :pt-options="{ mergeProps: true }"
    >
        <template #header></template>
        <template #closeicon></template>
        <IdentityVerificationReport
            :id="currentIdentityVerificationId"
            v-if="currentIdentityVerificationId"
            class="font-inter"
            @close="closeModal()"
        />
    </Dialog>
</template>

<script lang="ts" setup>
import { BRIDGE_EVENT_EMITTER, BRIDGE_EVENTS } from '@/shared/constants/event-emitter';
import Emittery from 'emittery';
import Dialog from 'primevue/dialog';
import { inject, ref } from 'vue';
import IdentityVerificationReport from './IdentityVerificationReport.vue';

/** This is a wrapper for the IdentityVerificationReport component. It exists as an entry point
 * while we are using a vue sprinkle to instantiate a vue app in the old jquery code.
 * The handling of events from external code should live here to keep components down unaware of that.
 * It should be kept thin and not contain business logic and should be removed once
 * we have a proper SPA replacing the current file management page or equivalent
 */

/** Receives a command via event to change the current event emitter. This event is fired by the older jquery code */
const currentIdentityVerificationId = ref<number>();
const dialogOpen = ref(false);
const bridgeEventEmitter = inject<Emittery>(BRIDGE_EVENT_EMITTER);

bridgeEventEmitter?.on(BRIDGE_EVENTS.IDV.CHANGE_CURRENT_IDV_REPORT, data => {
    currentIdentityVerificationId.value = undefined;
    if (!isNaN(Number(data?.id))) {
        currentIdentityVerificationId.value = Number(data.id);
        dialogOpen.value = true;
    }
});

function closeModal() {
    dialogOpen.value = false;
}
</script>
