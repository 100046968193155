export const IDV_HELPER_KYC_CHECK =
    'Know Your Customer (KYC) checks that information the client provides—such as name, date of birth, address, social security number—and matches records from multiple authoritative databases.';
export const IDV_HELPER_GOVERNMENT_ID_ANALYSIS =
    'Government ID Analysis pulls information from the provided ID and checks it against multiple authoritative databases and the issuing government entity. In addition, the ID is checked for common fraud and authenticity indicators.';
export const IDV_HELPER_ID_READABILITY = `ID Readability assesses whether the client's captured photo meets the quality required for further data analysis. 
    Common reasons for failure include out-of-focus images and glare. In such cases, clients are encouraged to retake the test to provide a clearer photo of the ID.`;
export const IDV_HELPER_ID_AUTHENTICITY = `ID Authenticity verifies whether the ID adheres to the criteria of an authentic ID by examining features, data and formatting. This comprehensive check ensures the integrity of the provided identification.`;
export const IDV_HELPER_DMV_VERIFICATION =
    'DMV Verification confirms if the data on the ID aligns with the information in the DMV database. While DMV data cannot be disclosed, a comparison is made to determine sufficient matching with the provided data.';
export const IDV_HELPER_SELFIE_VERIFICATION = `Selfie Verification assesses whether the client's captured photo meets the quality required for further data analysis.`;
export const IDV_HELPER_IP_ADDRESS_INFORMATION = `IP Address Information - This section allows you to better understand the subject's location based on their IP address and if technology to mask this information is being used.`;
export const IDV_HELPER_IP_ADDRESS_LOCATION =
    'The IP Address can be used to identify a general geographical location like city and state.';
export const IDV_HELPER_IP_HOSTNAME = 'Hostname is the name assigned to the network that directs online traffic.';
export const IDV_HELPER_IP_CITY = 'The city where the IP Address is located.';
export const IDV_HELPER_IP_REGION = 'The region where the IP Address is located.';
export const IDV_HELPER_IP_COUNTRY = 'The country where the IP Address is located';
export const IDV_HELPER_IP_LOCATION = 'Location is the latitude and longitude coordinates of the internet service provider.';
export const IDV_HELPER_PRIVACY = 'Privacy is a list of standard technologies used to mask an IP address or online behavior.';
export const IDV_HELPER_PRIVACY_VPN = `VPN indicates if a VPN was used to mask the user's lP address and online behavior which could be an indication of fraud.`;
export const IDV_HELPER_PRIVACY_PROXY = `Proxy indicates if a proxy server was used to mask the user's lP address which could be an indication of fraud.`;
export const IDV_HELPER_PRIVACY_TOR = `Tor indicates if a TOR network was used to mask the user's identity which is a strong indicator of fraud`;
export const IDV_HELPER_PRIVACY_RELAY = `Relay indicates if a private relay was used to mask the user's IP address and online behavior which could be an indication of fraud.`;
export const IDV_HELPER_ADDITIONAL_INFORMATION =
    'Additional Information allows you to enrich your understanding of the subject using key identifiers like email addresses, phone numbers and online presence pulled from authoritative data sources.';
export const IDV_HELPER_ADD_INFO_ADDRESS_LOOKUP =
    'The address lookup returns detailed metadata for a given address from authoritative data sources. Hover on a field below for detailed explanation.';
export const IDV_HELPER_ADD_INFO_FULLY_FORMATED_ADDRESS = 'The fully-formatted and corrected version of the address passed in.';
export const IDV_HELPER_ADD_INFO_RECORD_TYPE = 'Record Type indicates the type of record this address matched against.';
export const IDV_HELPER_ADD_INFO_COUNTY_FIPS =
    'County FIPS is the 5-digit county FIPS (Federal Information Processing Standards) code.';
export const IDV_HELPER_ADD_INFO_COUNTY_NAME = 'County Name is the name of the county in which the address is located.';
export const IDV_HELPER_ADD_INFO_PROPERTY_TYPE = 'Property Type indicates whether the address is residential or commercial.';
export const IDV_HELPER_ADD_INFO_DPV_MATCH = 'DPV Match indicates whether the USPS delivers mail to the address.';
export const IDV_HELPER_ADD_INFO_DPV_VACANT =
    'DPV Vacant indicates that a delivery point was active in the past but is currently vacant (in most cases, unoccupied over 90 days) and is not receiving deliveries';
export const IDV_HELPER_ADD_INFO_ACTIVE = 'Active indicates whether the address is active or not, based on USPS data';
export const IDV_HELPER_ADD_INFO_EMAIL_RISK =
    'Email Risk evaluates the fraud risk of an email address and confirms its authenticity. Hover on a field below for detailed explanation.';
export const IDV_HELPER_ADD_INFO_EMAIL_AGE = 'Age indicates the estimated age of the account.';
export const IDV_HELPER_ADD_INFO_EMAIL_REPUTABILITY =
    'Indicates whether the email has a high source of reputability or a low source of reputability.';
export const IDV_HELPER_ADD_INFO_EMAIL_SUSPICIOUS =
    'Suspicious indicates whether the email address should be treated as suspicious or risky.';
export const IDV_HELPER_ADD_INFO_EMAIL_DISPOSABLE = 'Indicates if the email is from an temporary/disposable service.';
export const IDV_HELPER_ADD_INFO_EMAIL_SPAMMY = 'Indicates if the email has exhibited spammy behavior';
export const IDV_HELPER_ADD_INFO_EMAIL_DELIVERABLE = 'Deliverable indicates whether or not the email address is valid.';
export const IDV_HELPER_ADD_INFO_EMAIL_DOMAIN_AGE = 'The number of days since the domain was created.';
export const IDV_HELPER_ADD_INFO_PHONE_RISK =
    'Phone Risk evaluates the fraud risk of a phone number and confirms its authenticity. ';
export const IDV_HELPER_ADD_INFO_PHONE_CLIENT_PHONE_NUMBER = 'Client provided phone number';
export const IDV_HELPER_ADD_INFO_PHONE_TYPE = 'Indicates the type of phone number being used';
export const IDV_HELPER_ADD_INFO_PHONE_ACCOUNT_HOLDER =
    'Account Holder is the name of the person associated with the carrier account.';
export const IDV_HELPER_ADD_INFO_PHONE_PHONE_CARRIER = 'Phone Carrier is the name of the phone carrier entity.';
export const IDV_HELPER_ADD_INFO_PHONE_RISK_LEVEL =
    'Risk Levels indicates the phone number risk level ranging from 1 (low) to 5 (high).';
export const IDV_HELPER_ADD_INFO_PHONE_RISK_RECOMMENDATION =
    'The recommended action based on the risk associated with the phone number.';
export const IDV_HELPER_ADD_INFO_PHONE_ADDRESS_COMPARISON =
    'Address Comparison indicates if the subject address matches the address for the account holder';
export const IDV_HELPER_ADD_INFO_PHONE_BIRTHDATE_COMPARISON =
    'Birthdate Comparison indicates if the subject birthdate matches the birthdate of the phone carrier';
