import { round } from 'lodash';

/** Rounds the fee to the nearest cent, rounding up for >= .5 cents and down for < .5 cents */
export const roundMoney = (amount: number | string, decimalPrecision = 2): number => {
    const numericInput = _paramToNumber(amount);
    if (!numericInput) return 0;
    return round(numericInput, decimalPrecision);
};

/**
 * @param amount A JS number to format as currency
 * @param currency Which system of currency to format `amount` into (see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#currency_2 MDN - Intl.NumberFormat#currency} for options)
 * @returns `amount` formatted into a string using the conventions of `currency`. Always shows two decimal places, and never shows a negative sign.
 * @example formatMoney(123.4); // "$123.40"
 */
export function formatMoney(amount: number, currency: Intl.NumberFormatOptions['currency'] = 'USD') {
    const amountToFormat = _paramToNumber(amount);
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        signDisplay: 'never',
        roundingMode: 'trunc',
    });
    return formatter.format(amountToFormat);
}

/**
 * Converts a quantity representing dollar-and-cents to a whole number of
 * pennies, truncating off any remaining decimal places after converting.
 */
export function convertDollarsToCents(amountInDollars: number | string) {
    const numberToConvert = _paramToNumber(amountInDollars);
    const numberIsUnsafe = numberToConvert > Number.MAX_SAFE_INTEGER || numberToConvert < Number.MIN_SAFE_INTEGER;
    if (!numberToConvert || numberIsUnsafe) {
        return 0;
    }
    return Math.round(numberToConvert * 100);
}

/**
 * Helper to provide consistent defensive logic for functions that will
 * break if they aren't passed something that can be parsed as a number.
 */
const _paramToNumber = (param: unknown, fallback = 0): number => {
    const numericInput = Number(param);
    return isNaN(numericInput) ? fallback : numericInput
}
