<template>
    <DropdownEditMenu>
        <li>
            <a
                :class="linkClasses"
                :href="downloadUrl"
                target="_blank"
            >
                View ClosingLock Report
            </a>
        </li>
        <li>
            <a
                :class="linkClasses"
                target="_blank"
                :href="idvEntry.persona_link"
            >
                View Persona Decision
            </a>
        </li>
        <li>
            <a
                :class="linkClasses"
                target="_blank"
                @click="$emit('edit-decision', idvEntry)"
            >
                Edit Decision
            </a>
        </li>
    </DropdownEditMenu>
</template>
<script setup lang="ts">
import { IDVReviewEntry } from '@/api/interfaces/identity-verification.api';
import DropdownEditMenu from '@/components/Components/DropdownEditMenu.vue';
import { useIdentityVerificationService } from '@/services/identity-verification.service';
import { computed } from 'vue';
const identityVerificationService = useIdentityVerificationService();
const linkClasses =
    'font-medium text-sm flex items-center gap-2 py-2 px-3 mx-1 text-gray-600 cursor-pointer no-underline hover:bg-primary-50';

const props = defineProps<{
    idvEntry: IDVReviewEntry;
}>();
defineEmits<{
    (event: 'edit-decision', idvEntry: IDVReviewEntry): void;
}>();

const downloadUrl = computed(() => identityVerificationService.getDownloadUrl(props.idvEntry.id));
</script>
