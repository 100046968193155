<template>
    <div
        class="flex flex-row pt-[15px] pb-2 pr-20"
        :class="{
            'border-b border-b-slate-200': withDivider,
            'pl-2': indentationLevel === 0,
            'pl-[18px]': indentationLevel === 1,
            'pl-8': indentationLevel === 2,
        }"
    >
        <div class="flex-1 text-sm">
            <div v-if="label">{{ label }}</div>
            <slot v-if="!checkNotFound">
                <div
                    v-if="!omitCheckText && checkResult?.text"
                    class="font-bold text-slate-800 text-sm"
                >
                    {{ checkResult?.text }}
                </div>
            </slot>
        </div>
        <div class="w-3/12 text-right flex flex-col justify-end">
            <slot name="check-result">
                <CheckStateResult
                    v-if="checkResult"
                    :value="checkResult"
                    class="font-semibold text-sm"
                />
            </slot>
        </div>
    </div>
    <CheckErrorList
        :value="checkResult"
        v-if="checkResult && !omitErrorList"
        :indentation-level="indentationLevel"
    />
</template>

<script lang="ts" setup>
import type { CheckResult } from '@/api/interfaces/identity-verification-report.api';
import { CheckResultState } from '@/api/interfaces/identity-verification-report.api';
import { computed } from 'vue';
import CheckErrorList from './CheckErrorList.vue';
import CheckStateResult from './CheckStateResult.vue';

const props = withDefaults(
    defineProps<{
        checkResult?: CheckResult;
        label?: string;
        omitCheckText?: boolean;
        indentationLevel?: number;
        omitErrorList?: boolean;
        withDivider?: boolean;
    }>(),
    {
        omitCheckText: false,
        indentationLevel: 1,
        omitErrorList: false,
        withDivider: true,
    },
);

const checkNotFound = computed(() => {
    return props.checkResult?.state === CheckResultState.NotFound;
});
</script>
