<template>
    <div class="relative lg:flex items-center ml-auto mr-3">
        <Menubar
            :model="menuItems"
            :pt="{
                root: { class: 'bg-white border-none focus:bg-white p-0' },
                menu: {
                    class: 'min-w-max left-auto right-0 font-inter text-xs font-semibold no-underline uppercase text-neutral-500 animate-fade-in',
                },
                content: { class: 'bg-white hover:bg-white border-none text-inherit hover:text-inherit' },
                menuitem: {
                    class: 'bg-white border-none focus:bg-white',
                },
                button: { class: 'focus:bg-white active:bg-white' },
                submenu: 'max-w-max animate-fade-in',
            }"
        />
    </div>
</template>

<script lang="ts" setup>
import { useCurrentUserService } from '@/services/current-user.service';
import Menubar from 'primevue/menubar';
import { activeMenuItem } from './navigation-helper';
const currentUserService = useCurrentUserService();

const logoutForm = ref<HTMLFormElement>();

import { ref } from 'vue';
const menuItems = ref([
    { label: 'ID Verification Review', url: '/spa/id-verification-review', class: activeMenuItem(['/id-verification-review']) },
    { label: 'Wire Requests', url: '/wireinstructionrequests', class: activeMenuItem(['/wireinstructionrequests']) },
    { label: 'Paused Payments', url: '/payments/failedPayments', class: activeMenuItem(['/payments/failedPayments']) },
    {
        label: currentUserService.currentUser?.name,
        items: [
            {
                label: 'Logout',
                class: 'text-red-500',
                command: () => {
                    logoutForm.value?.submit();
                    currentUserService.logout();
                },
            },
        ],
    },
]);

defineSlots<{ csrfField(): void; warning(): void; rightNavigation(): void }>();
</script>
