<template>
<Modal
    v-model:visible="showUpdateModal"
    body-class="update-emd-modal"
    footer-class="justify-end"
    title="Update EMD"
    fullscreen-on-mobile
    :event-to-close-function="() => $emit('cancel')"
>
    <p v-if="error" class="bg-rose-100 text-danger py-4 px-6 rounded-md text-base">
        There was an error updating this record. Please try again, or contact support if you continue to experience the problem.
    </p>

    <form class="grid grid-cols-[1fr_3fr] gap-y-4" @submit.prevent="confirmUpdate">
        <span class="pt-2">Property Address</span>
        <!-- <AddressAutocompleteSearch html-input-class="form-control p-4 font-[Hind,'Open_Sans']" :initial-value="earlyDepositPayload.address" @location-selected="onLocationSelected" /> -->
        <input class="form-control p-3" name="address" v-model="payloadFields.address" required>

        <span class="pt-2">Deposit Amount</span>
        <AutoNumericInput
            name="deposit_amount"
            class="form-control p-3"
            v-model.cents="payloadFields.deposit_amount!"
            required
        />

        <input type="submit" class="hidden" :disabled="isMissingField"> <!-- allows user to submit with 'enter' -->
    </form>

    <template #footer>
        <button 
            class="rounded-md py-4 px-6 bg-primary text-white" 
            :class="disabledCss('bg-neutral-300', 'opacity-75', 'hover:bg-neutral-300', 'cursor-not-allowed', 'shadow-none')" 
            :disabled="isMissingField"
            @click="confirmUpdate" 
        >
            Confirm
        </button>
        <button class="rounded-md py-4 px-6" @click="$emit('cancel')">Cancel</button>
    </template>
</Modal>
</template>

<script setup lang="ts">
import type { EarlyDeposit } from '@/api/interfaces/early-deposit-types';
import AutoNumericInput from '@/components/Components/AutoNumericInput.vue';
import Modal from '@/components/Components/Modal/Index.vue';
import { useEditEarlyDeposit } from '@/services/early-deposit.service';
import { computed, reactive, ref, watch } from 'vue';

const showUpdateModal = ref(false);
const props = defineProps<{ earlyDeposit?: EarlyDeposit }>();
const emit = defineEmits<{
    'cancel': [],
    'emd-updated': [operation: 'update', emd: EarlyDeposit],
}>();
const payloadFields: Partial<EarlyDeposit> = reactive({});
const isMissingField = computed<boolean>(() => !payloadFields.id || !payloadFields.address || !payloadFields.deposit_amount)
const isPayloadReady = (payload: Partial<EarlyDeposit>): payload is EarlyDeposit => !isMissingField.value;

const { error, response, statusCode, updateEarlyDeposit } = useEditEarlyDeposit();

const confirmUpdate = async () => {
    if (!isPayloadReady(payloadFields)) return console.warn('Cannot update EMD without all required fields.');

    await updateEarlyDeposit(payloadFields);
    if (error.value) return;

    // toastr.success('Record updated', 'Success!');
    emit('emd-updated', 'update', { ...payloadFields });
}

const disabledCss = (...classes: string[]): string =>
    classes.reduce((acc, css) => `${acc} disabled:${css}`, '');

watch(() => props.earlyDeposit, (rowData) => {
    showUpdateModal.value = !!rowData;
    Object.assign(payloadFields, rowData);
})

watch(error, (error) => {
    if (error) {
        const responseJSON = JSON.stringify(response.value?.json(), undefined, 2);
        window.Bugsnag?.notify({
            errorClass: error,
            errorMessage: `EMD could not be updated. Server response (code: ${statusCode}):\n${responseJSON}`,
        })
    }
})
</script>

<style scoped>
.update-emd-modal input {
    line-height: 1;
}

.font-hind-open-sans {
    font-family: Hind, 'Open Sans', Arial, serif;
}
</style>
