<template>
    <div
        v-if="tableDataLoading"
        class="flex content-center w-full items-center justify-center h-96"
    >
        <LoadingSpinner />
    </div>
    <!-- if we have no tableData show an empty div -->
    <div v-else-if="tableData.length === 0">
        <h3 class="flex text-center">No Reviews Needed</h3>
    </div>
    <div v-else-if="tableDataError">
        <h3 class="flex text-center">Error Loading Reviews</h3>
    </div>
    <div v-else>
        <h2 class="text-xl text-900 font-bold capitalize pb-2">
            {{ label }}
        </h2>

        <DataTable
            tableStyle="min-width: 50rem"
            :value="tableData"
            :paginator="tableData.length > 10"
            :rows="10"
        >
            <Column
                :pt="columnStyles.columnLeft"
                field="ref_number"
                header="Ref Number"
            >
                <template #body="{ data: rowData }: { data: IDVReviewEntry }">
                    <p
                        class="break-keep max-w-48 cl-clamp-line"
                        v-tooltip.top="getTooltipOptions(rowData.ref_number)"
                    >
                        {{ rowData.ref_number }}
                    </p>
                    <a
                        :href="rowData.persona_link"
                        target="_blank"
                        class="text-blue-500 underline text-xs"
                        >Persona Link</a
                    >
                </template>
            </Column>
            <Column
                :pt="columnStyles.columnLeft"
                field="client_email"
                header="Client"
            >
                <template #body="{ data: rowData }: { data: IDVReviewEntry }">
                    <span class="capitalize">{{ rowData.client_type }}</span>
                    <br />
                    <span
                        v-tooltip.top="getTooltipOptions(rowData.client_email)"
                        class="text-xs break-keep max-w-50 cl-clamp-line"
                        >{{ rowData.client_email }}</span
                    >
                </template>
            </Column>
            <Column
                :pt="columnStyles.columnLeft"
                field="account_name"
                header="Account/Branch"
            >
                <template #body="{ data: rowData }: { data: IDVReviewEntry }">
                    <p
                        class="break-keep max-w-36 cl-clamp-line"
                        v-tooltip.top="getTooltipOptions(rowData.account_name)"
                    >
                        {{ rowData.account_name }}
                    </p>
                    <p
                        class="break-keep max-w-36 cl-clamp-line"
                        v-tooltip.top="getTooltipOptions(rowData.branch_name)"
                    >
                        {{ rowData.branch_name }}
                    </p>
                </template>
            </Column>
            <Column
                :pt="columnStyles.columnLeft"
                field="created_at"
                header="Completed Date"
            >
                <template #body="{ data: rowData }: { data: IDVReviewEntry }">
                    <span>{{ formatDate(rowData.created_at) }}</span>
                </template>
            </Column>
            <Column
                :pt="columnStyles.columnLeft"
                field="last_review"
                header="Reviewed By"
            >
                <template #body="{ data: rowData }: { data: IDVReviewEntry }">
                    <div v-if="!!rowData.last_review">
                        <p
                            class="break-keep max-w-48 cl-clamp-line"
                            v-tooltip.top="getTooltipOptions(rowData.last_review?.name)"
                        >
                            {{ rowData.last_review?.name }}
                        </p>
                        <span>{{ rowData.last_review?.created_at || '---' }}</span>
                    </div>
                    <div v-else>
                        <span>---</span>
                    </div>
                </template>
            </Column>

            <Column
                :pt="columnStyles.columnLeft"
                field="updated_at"
                header="Last Update"
            >
                <template #body="{ data: rowData }: { data: IDVReviewEntry }">
                    <span v-if="rowData.updated_at">{{ formatDate(rowData.updated_at) }}</span>
                    <span v-else-if="rowData.created_at">{{ formatDate(rowData.created_at) }}</span>
                    <span v-else>---</span>
                </template>
            </Column>

            <Column
                :pt="columnStyles.columnLeft"
                field="status"
                header="Status"
            >
                <template #body="{ data: rowData }: { data: IDVReviewEntry }">
                    <span :class="statusMap[rowData.status]?.color">
                        {{ statusMap[rowData.status]?.text || rowData.status }}
                    </span>
                </template>
            </Column>
            <Column
                :pt="columnStyles.columnRight"
                field="id"
                header="Actions"
            >
                <template #body="{ data: rowData }: { data: IDVReviewEntry }">
                    <IdentityVerificationActions
                        :idvEntry="rowData"
                        @edit-decision="idvEntryToUpdate = rowData"
                    />
                </template>
            </Column>
        </DataTable>
        <!-- listen for our emit -->
    </div>
    <IdentityVerificationReviewForm
        v-if="idvEntryToUpdate"
        :idvEntryToUpdate="idvEntryToUpdate.id"
        @cancel-idv-update="closeEditDecisionModal"
        @update-idv-status="updateIdvStatus"
        confirmActionText="Submit New Result"
        cancelActionText="Cancel"
    />
    <Toast />
</template>

<script setup lang="ts">
import type { IDVEmployeeStatuses, IDVFilterTypes, IDVReviewEntry } from '@/api/interfaces/identity-verification.api';
import { IDVStatus } from '@/api/interfaces/identity-verification.api';
import IdentityVerificationReviewForm from '@/components/Components/IdentityVerificationReview/IdentityVerificationReviewForm.vue';
import LoadingSpinner from '@/components/Icons/LoadingSpinner.vue';
import IdentityVerificationActions from '@/employee-portal/pages/identity-verification-review/IdentityVerificationActions.vue';
import { useIdentityVerificationService } from '@/services/identity-verification.service';
import { formatDate } from '@/shared/utils/format-date';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

type IDVStatusMap = { [key in IDVStatus]: { text: string; color?: string } };

const toast = useToast();

const qsForLabel = {
    All: '',
    'Completed Review': 'completed_review',
} as const;

const identityVerificationService = useIdentityVerificationService();
const idvEntryToUpdate = ref<IDVReviewEntry | null>(null);
const tableDataLoading = ref<boolean>(false);
const tableDataError = ref<boolean>(false);
const props = withDefaults(
    defineProps<{
        label: keyof typeof qsForLabel;
    }>(),
    { label: 'All' },
);

const updateIdvStatus = async ({ idv, status, reason }: { idv: IDVReviewEntry['id']; status: IDVStatus; reason: string }) => {
    const allowedEmployeeStatuses = [IDVStatus.confirmed_failed, IDVStatus.amended];

    try {
        if (allowedEmployeeStatuses.indexOf(status) < 0) {
            throw new Error(`employee attempted to update status to invalid value: ${status}`);
        }

        await identityVerificationService.employeeUpdateIdentityVerificationEntry(idv, {
            status: status as IDVEmployeeStatuses,
            reason,
        });
        fetchData();
    } catch (error) {
        console.error('Error updating IDV status', error);
        toast.add({ severity: 'error', summary: 'Error updating IDV status' });
    } finally {
        closeEditDecisionModal();
    }
};

const closeEditDecisionModal = () => {
    idvEntryToUpdate.value = null;
};

const statusMap = <IDVStatusMap>{
    overridden: { text: 'Overridden', color: 'text-orange-500' },
    failed: { text: 'Failed', color: 'text-red-500' },
    confirmed_failed: { text: 'Confirmed Failed', color: 'text-red-500' },
    amended: { text: 'Amended', color: 'text-yellow-500' },
};

watch(
    () => props.label,
    () => {
        fetchData();
    },
);

const fetchData = async () => {
    tableDataLoading.value = true;
    const qsStatus: IDVFilterTypes = qsForLabel[props.label];
    try {
        await identityVerificationService.fetchIdentityVerificationEntries(qsStatus);
    } catch (error) {
        tableDataError.value = true;
    } finally {
        tableDataLoading.value = false;
    }
};

onMounted(() => {
    fetchData();
});

// set tableData from computed of identityVerificationService.identityVerificationEntries getter
const tableData = computed(() => identityVerificationService.idvReviewEntries || []);

onUnmounted(() => {
    tableDataLoading.value = false;
    tableDataError.value = false;
});

// computed columnStyles
const columnStyles = {
    columnLeft: {
        headerCell: 'bg-gray-100 border-t border-t-gray-200 border-b-gray-200 pt-2 pb-2',
        bodyCell: 'border-l-0 border-r-0 border-b border-gray-200 pt-2 pb-2',
    },
    columnRight: {
        headerCell: 'bg-gray-100 border-t border-t-gray-200 border-b-gray-200 pt-2 pb-2 text-right flex justify-end',
        bodyCell: 'border-l-0 border-r-0 border-b border-gray-200 pt-2 pb-2 text-right pr-4',
    },
};

const tooltipOptions = (value: string) => ({
    value,
    fitContent: true,
    pt: {
        root: 'p-0',
        arrow: 'hidden',
        text: 'px-2 py-1 text-sm leading-1 font-inter',
    },
});

// Reusable function to provide tooltip options
const getTooltipOptions = (value: string) => tooltipOptions(value);
</script>

<style scoped>
.cl-clamp-line {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.max-w-36 {
    max-width: 9rem;
}
.max-w-48 {
    max-width: 12rem;
}
.max-w-50 {
    max-width: 12.5rem;
}
</style>
