export enum IDVStatus {
    success = 'success',
    requested = 'requested',
    failed = 'failed',
    overridden = 'overridden',
    confirmed_failed = 'confirmed_failed',
    amended = 'amended',
}

export type IDVEmployeeStatuses = IDVStatus.confirmed_failed | IDVStatus.amended;

export interface IDVReviewEntry {
    id: number;
    ref_number: string;
    persona_id: string;
    persona_link: string;
    client_type: string;
    client_email: string;
    account_name: string;
    branch_name: string;
    branch_id: number;
    account_id: number;
    created_at: string;
    updated_at: string;
    last_review?: {
        name: string;
        created_at: string;
    };
    status: IDVStatus;
}

export type IdvReviewPayload = {
    reason: string;
    status: IDVStatus;
};

export type IDVReviewEntriesFetchResponse = {
    data: IDVReviewEntry[];
};

export type IDVFilterTypes = '' | 'completed_review';
