import { TooltipOptions } from 'primevue/tooltip';

export const GEN_IDV_STYLED_TOOLTIP = (text: string = '', { allowWhitespace = false } = {}) =>
    ({
        value: allowWhitespace ? text : text.trim().replace(/\s/g, ' '),
        fitContent: true,
        pt: {
            root: { class: 'max-w-sm w-fit' },
            text: { class: 'bg-gray-900 text-neutral-50 max-w-6' },
        },
    }) as TooltipOptions;
