<template>
<input ref="inputRef" @input="updateModel">
</template>

<script setup lang="ts">
import { convertDollarsToCents } from '@/shared/utils/format-number';
import AutoNumeric from 'autonumeric';
import { onMounted, ref } from 'vue';

const [model, modifiers] = defineModel<number>({ required: true });
const updateModel = () => {
    const num = autoNumericInstance?.getNumber() ?? NaN;
    model.value = modifiers.cents ? convertDollarsToCents(num) : num;
}

const inputRef = ref<HTMLInputElement>();
let autoNumericInstance: AutoNumeric;

onMounted(() => {
    autoNumericInstance = new AutoNumeric(inputRef.value!, { currencySymbol: '$', selectOnFocus: false, minimumValue: '0' });
    const initialValue = model.value / (modifiers.cents ? 100 : 1);
    autoNumericInstance.set(initialValue);
})
</script>
