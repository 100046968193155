<template>
<DataTable
    :value="tableData"
    data-key="id"
    @row-click="toggleRowExpand"
    @transitionend="clearRecentRecord"
    v-model:expanded-rows="expandedRows"
    :pt="{ rowExpansionCell: 'border-transparent' }"
    table-class="view-emd-table"
    :row-class="getRowClasses"
    class="md:pr-6 xs:pr-2 max-w-screen-2xl m-auto"
>
    <template v-if="pageFetchError" #header>
        <p class="bg-rose-100 text-danger py-4 px-6 rounded-md text-base">
            There was an error fetching data for this table. Please try again, or contact support if you continue to experience the problem.
        </p>
    </template>
    <Column field="address" header="Property Address" body-class="text-lg font-medium border-x-transparent" class="address-col">
        <template #body="{ data }">
            <div class="flex items-center show-expand-toggle">
                {{ data.address }}
            </div>
        </template>
    </Column>
    <Column field="branch.title.name" header="Company" body-class="border-x-transparent" />
    <Column field="branch.name" header="Branch" body-class="border-x-transparent" />
    <Column field="created_at" header="Created" body-class="border-x-transparent" class="created-col">
        <template #body="{ data: rowData }: { data: EarlyDeposit }">
            {{ formatDate(rowData.created_at, MONTH_DAY_YEAR_FORMAT, rowData.created_at) }}
        </template>
    </Column>
    <Column header="Actions" body-class="border-x-transparent" class="actions-col">
        <template #body="{ data: rowData }: { data: EarlyDeposit }">
            <div class="flex justify-end gap-1 px-2 dim-other-btns">
                <button class="px-1 options-icon shadow-none transition-opacity duration-150 text-primary" @click.stop="updateModalData = rowData" title="Update early deposit">
                    <i class="fa-edit fas" />
                </button>
                <button class="px-1 options-icon shadow-none transition-opacity duration-150 text-danger" @click.stop="deleteModalData = rowData" title="Delete early deposit">
                    <i class="fa-times fas" />
                </button>
            </div>
        </template>
    </Column>
    <template #expansion="{ data: rowData, index }: { data: EarlyDeposit, index: number }">
        <EarlyDepositTableRowDetails :row-data :key="index" />
    </template>
</DataTable>

<EarlyDepositTablePaginator v-bind="paginatorInfo" v-model="pagePayloadRef" @page="expandedRows = {}" />

<UpdateEarlyDepositModal
    :earlyDeposit="updateModalData"
    @emd-updated="onEmdModified"
    @cancel="updateModalData = undefined"
/>

<DeleteEarlyDepositModal
    :earlyDeposit="deleteModalData"
    @emd-deleted="onEmdModified"
    @cancel="deleteModalData = undefined"
/>
</template>

<script setup lang="ts">
import type { EarlyDeposit, EarlyDepositTableResponse } from '@/api/interfaces/early-deposit-types';
import { useEarlyDepositsPaged } from '@/services/early-deposit.service';
import { formatDate, MONTH_DAY_YEAR_FORMAT } from '@/shared/utils/format-date';
import Column from 'primevue/column';
import type { DataTableRowClickEvent } from 'primevue/datatable';
import DataTable from 'primevue/datatable';
import { computed, nextTick, reactive, ref, watch } from 'vue';
import DeleteEarlyDepositModal from './DeleteEarlyDepositModal.vue';
import EarlyDepositTablePaginator from './EarlyDepositTablePaginator.vue';
import EarlyDepositTableRowDetails from './EarlyDepositTableRowDetails.vue';
import UpdateEarlyDepositModal from './UpdateEarlyDepositModal.vue';

interface EarlyDepositClickEvent extends DataTableRowClickEvent {
    data: Pick<EarlyDeposit, 'id'>
}

const expandedRows = ref<Record<EarlyDeposit['id'], boolean>>({});
const deleteModalData = ref<EarlyDeposit>();
const updateModalData = ref<EarlyDeposit>();
const PAGINATOR_INFO_DEFAULTS = Object.freeze({
    current_page: 0,
    from: 0,
    last_page: 0,
    links: [],
    path: '',
    per_page: 0,
    to: 0,
    total: 0,
});

const { pageRef: pagePayloadRef, data: pageOfEarlyDepositsRef, error: pageFetchError, execute: refetchCurrentPage } =
    useEarlyDepositsPaged();
const tableData = computed<EarlyDeposit[]>(() => pageOfEarlyDepositsRef.value?.data ?? []);
const paginatorInfo = computed<EarlyDepositTableResponse['meta']>(
    () => pageOfEarlyDepositsRef.value?.meta ?? PAGINATOR_INFO_DEFAULTS
);

watch(pageFetchError, (error) => {
    if (error) {
        window.Bugsnag.notify({
            errorClass: error,
            errorMessage: 'Error fetching a page of early deposits (EMDs)',
        })
    }
})

const highlightModifiedRow = reactive<{
    id: EarlyDeposit['id'], operation: '' | 'delete' | 'update'
}>({
    id: '', operation: '',
});
const onEmdModified = (operation: 'delete' | 'update', modifiedEmd: Pick<EarlyDeposit, 'id'> & Partial<EarlyDeposit>) => {
    highlightModifiedRow.id = modifiedEmd.id;
    highlightModifiedRow.operation = operation;

    if (operation === 'update') updateModalData.value = undefined;
    else if (operation === 'delete') {
        deleteModalData.value = undefined;
        if (Object.keys(expandedRows.value).includes(modifiedEmd.id)) {
            toggleRowExpand({ data: modifiedEmd })
        }
    }
}

const REFETCH_PAGE = 'refetch-page';
const clearRecentRecord = async (evt: TransitionEvent) => {
    if (!(evt.target instanceof HTMLTableRowElement)) return;
    highlightModifiedRow.id = '';
    highlightModifiedRow.operation = '';
    if (evt.target.classList?.contains(REFETCH_PAGE)) {
        await refetchCurrentPage();

        // PrimeVue <DataTable> is not correctly placing expanded rows after a delete;
        // any expanded rows below the deleted row will be in the wrong position, so
        // close and reopen everything to refresh. Maybe this will be better in v4?
        const rows = expandedRows.value;
        expandedRows.value = {};
        nextTick(() => { expandedRows.value = rows });
    }
}

const toggleRowExpand = (arg: Pick<EarlyDepositClickEvent, 'data'>) => {
    const rowId = arg.data.id;
    const expandedRowsCopy = { ...expandedRows.value };
    
    if (rowId in expandedRowsCopy) delete expandedRowsCopy[rowId];
    else expandedRowsCopy[rowId] = true;

    expandedRows.value = expandedRowsCopy;
}

const getRowClasses = (rowData: EarlyDeposit) => {
    const RECENTLY_DELETED_CSS = `${REFETCH_PAGE} transition-all bg-rose-100 line-through`;
    const RECENTLY_UPDATED_CSS = `${REFETCH_PAGE} transition-all bg-emerald-100`;
    const rowIdModified = highlightModifiedRow.id;
    const rowModification = highlightModifiedRow.operation;
    return {
        'duration-1000': true,
        'is-expanded-row': rowData.id in expandedRows.value,
        [RECENTLY_DELETED_CSS]: rowModification === 'delete' && rowData.id === rowIdModified,
        [RECENTLY_UPDATED_CSS]: rowModification === 'update' && rowData.id === rowIdModified,
    };
};
</script>

<style lang="scss" scoped>
:deep(.view-emd-table) {
    .actions-col {
        width: 6ch;
    }
}

.dim-other-btns:has(button:hover) {
    button:not(:hover) {
        opacity: 0.5;
    }
}

.show-expand-toggle::before {
    --content: '+';
    --bg-color: #0b0;

    content: var(--content);
    cursor: pointer;
    font: 0.75em/1 monospace;
    display: flex;
    align-items: center;
    border-radius: 100%;
    background-color: var(--bg-color);
    color: white;
    margin-right: 1ch;
    padding: 0.25em;
    padding-bottom: 0.3em; // better visual centering
    height: 1.15em;
    width: 1.15em;
    transition: background-color 150ms linear;
}

.is-expanded-row .show-expand-toggle::before {
    --content: "-";
    --bg-color: #b00;
}
</style>