<template>
    <div
        ref="googleMapsStage"
        class="w-full h-76"
        :style="{ width, height }"
    >
        <!-- Google maps instance will be injected here -->
        <!--
            Do not condition the instantiation (v-if) of this tag or
            google maps will fail if the node is not here
          -->
    </div>
</template>

<script lang="ts" setup>
import { useGoogleMaps } from '@/services/google-maps.service';
import { CSSProperties, effect, ref } from 'vue';

let googleMapsInstance: google.maps.Map | undefined = undefined;
const googleMapsService = useGoogleMaps();
const googleMapsStage = ref<HTMLDivElement>();
const markerInstances = ref<google.maps.marker.AdvancedMarkerElement[]>([]);

const props = withDefaults(
    defineProps<{
        mapCenter: google.maps.LatLngLiteral;
        markerCoordinates: google.maps.LatLngLiteral[];
        width?: CSSProperties['width'];
        height?: CSSProperties['width'];
    }>(),
    {
        width: '100%',
        height: '260px',
    },
);

const DEFAULT_MAP_ZOOM = 15;

function createMapInstance() {
    if (googleMapsService.libraryLoad === 'ready' && props.mapCenter && googleMapsStage.value) {
        googleMapsInstance = googleMapsService.createMap(googleMapsStage.value, {
            center: props.mapCenter,
            zoom: DEFAULT_MAP_ZOOM,
            mapId: import.meta.env.VITE_GOOGLE_MAPS_STYLE_ID,
            disableDefaultUI: true,
            gestureHandling: 'cooperative',
        });
        return;
    }
    console.error('Map instance could not be created');
}

function refreshMap(mapCenter: google.maps.LatLngLiteral, markerCoordinates: google.maps.LatLngLiteral[]) {
    createMapInstance();

    if (!googleMapsInstance) {
        console.error('attemped to refresh map without a valid instance');
        return;
    }
    if (mapCenter) {
        googleMapsInstance.setCenter(mapCenter);
        googleMapsInstance.setZoom(DEFAULT_MAP_ZOOM);
    }

    markerInstances.value.forEach(marker => {
        marker.remove();
    });

    markerInstances.value =
        (markerCoordinates
            ?.map(position => {
                const marker = googleMapsService.attachMarker({
                    position,
                    map: googleMapsInstance,
                });
                return marker;
            })
            ?.filter(Boolean) as google.maps.marker.AdvancedMarkerElement[]) || [];
}

effect(() => {
    if (googleMapsService.libraryLoad && googleMapsStage.value) {
        refreshMap(props.mapCenter, props.markerCoordinates);
    }
});
</script>
