<template>
    <TabNavContainer title="Files">
        <template #navigation>
            <TabMenu
                :model="tabs"
                :activeIndex="activeIndex"
            />
        </template>
    </TabNavContainer>
</template>

<script lang="ts" setup>
import TabMenu from 'primevue/tabmenu';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import TabNavContainer from './TabNavContainer.vue';

interface Tab {
    label: string
    url: string
}

const route = useRoute();

const props = defineProps<{
    hasEarlyDepositAccess: boolean;
    hasPostCloseAccess: boolean;
}>();

const earlyDepositTab = props.hasEarlyDepositAccess && { label: 'Early Deposit', url: '/user/early-deposit' }
const preCloseTab = { label: 'Pre-Close', url: '/user/home' }
const postCloseTab = props.hasPostCloseAccess && { label: 'Post-Close', url: '/user/postclose' }

const tabs = ref<Tab[]>(
    [earlyDepositTab, preCloseTab, postCloseTab].filter(item => !!item)
);

const activeIndex = ref(tabs.value.findIndex(item => item.url === route.path));
</script>
