<script lang="ts" setup>
import { EditAccountNumberSubmitPayload } from '@/employee-portal/pages/accounts/forms/form-payloads';
import { FlatWireDoc } from '@/interfaces/payment-settings/escrow-account';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { useField, useForm } from 'vee-validate';
import { onMounted } from 'vue';

const props = defineProps<{ flatWireDoc: FlatWireDoc }>();
const emits = defineEmits<{
    (event: 'submit', payload: EditAccountNumberSubmitPayload): void;
    (event: 'cancel'): void;
}>();
const { handleSubmit } = useForm();

const { value: accountNumber, errorMessage: accountNumberErrorMessage } = useField<string>('accountNumber', value =>
    value ? true : 'Add an account number',
);
const { value: accountNumberConfirmation, errorMessage: accountNumberConfirmationErrorMessage } = useField<string>(
    'accountNumberConfirmation',
    value => {
        if (!value) {
            return 'Input the account number for confirmation';
        }
        if (value !== accountNumber.value) {
            return 'The account numbers do not match';
        }
        return true;
    },
);

const onSubmit = handleSubmit(values => {
    emits('submit', {
        flatWireDoc: props.flatWireDoc,
        values: {
            accountNumber: values.accountNumber,
        },
    });
});

const onCancel = () => {
    emits('cancel');
};

onMounted(() => {
    accountNumber.value = props.flatWireDoc.account_number;
    accountNumberConfirmation.value = props.flatWireDoc.account_number;
});
</script>

<template>
    <form @submit="onSubmit">
        <h3 class="text-center font-semibold mb-9">Enter the Account Number</h3>
        <div class="flex flex-col gap-8 font-medium">
            <div class="flex flex-col gap-1">
                <label
                    for="acc-number"
                    class="text-sm font-normal"
                    >Account Number:</label
                >
                <InputText
                    id="acc-number"
                    class="input-blur"
                    v-model="accountNumber"
                    type="text"
                    autocomplete="off"
                    @copy="e => e.preventDefault()"
                    @cut="e => e.preventDefault()"
                    @paste="e => e.preventDefault()"
                />
                <small
                    class="text-red-500 text-xs"
                    v-show="accountNumberErrorMessage"
                    >{{ accountNumberErrorMessage }}</small
                >
            </div>
            <div class="flex flex-col gap-1">
                <label
                    for="acc-number-confirmation"
                    class="text-sm font-normal"
                    >Confirm Account Number:</label
                >
                <InputText
                    id="acc-number-confirmation"
                    class="input-blur"
                    v-model="accountNumberConfirmation"
                    type="text"
                    autocomplete="off"
                    @copy="e => e.preventDefault()"
                    @cut="e => e.preventDefault()"
                    @paste="e => e.preventDefault()"
                />
                <small
                    class="text-red-500 text-xs"
                    v-show="accountNumberConfirmationErrorMessage"
                    >{{ accountNumberConfirmationErrorMessage }}</small
                >
            </div>
            <div class="flex flex-col gap-1">
                <Button
                    label="Save Account Number"
                    type="submit"
                ></Button>
                <Button
                    outlined
                    label="Cancel"
                    type="button"
                    @click="onCancel"
                ></Button>
            </div>
        </div>
    </form>
</template>
@/api/interfaces/escrow-account
