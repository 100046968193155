import type { IDVReviewEntry } from '@/api/interfaces/identity-verification.api';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useEmployeeIdentityVerificationStore = defineStore(
    'employeeIdentityVerification',
    () => {
        const idvReviewEntries = ref<IDVReviewEntry[]>([]);

        function setIdvReviewEntries(entries: IDVReviewEntry[] = []) {
            idvReviewEntries.value = entries;
        }

        function updateIdvReviewEntry(entry: IDVReviewEntry) {
            const index = idvReviewEntries.value.findIndex(e => e.id === entry.id);
            if (index !== -1) {
                idvReviewEntries.value[index] = entry;
            }
        }

        return {
            idvReviewEntries,
            updateIdvReviewEntry,
            setIdvReviewEntries,
        };
    },
    {
        persist: false,
    },
);
