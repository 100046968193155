import { reactive, ref } from 'vue';

/** This service assumes a global instance of google maps script has been added */
export const useGoogleMaps = () => {
    const loadCheckIntervalHandler = ref();
    const libraryLoad = ref<'not_loaded' | 'ready' | 'error'>(window.google?.maps?.Map ?? false ? 'ready' : 'not_loaded');

    /** Periodical check for google maps instance to account for older script injections across the old portals */
    function checkIfLibLoaded() {
        if (window?.google?.maps?.Map) {
            clearInterval(loadCheckIntervalHandler.value);
            libraryLoad.value = 'ready';
        }
    }

    function createMap(element: HTMLElement, options: google.maps.MapOptions) {
        if (!element) {
            console.error('attempted to create map without a valid root element');
            return;
        }
        if (window?.google?.maps?.Map) {
            return new window.google.maps.Map(element, options);
        }
        console.error('google maps could not be initialized: lib is not loaded', { libraryLoad: libraryLoad.value });
    }

    function attachMarker(options: google.maps.marker.AdvancedMarkerElementOptions) {
        if (window?.google?.maps?.marker.AdvancedMarkerElement) {
            return new window.google.maps.marker.AdvancedMarkerElement(options);
        }
    }

    loadCheckIntervalHandler.value = setInterval(checkIfLibLoaded, 100);

    return reactive({
        libraryLoad,
        createMap,
        attachMarker,
    });
};
