<template>
    <IdentityVerificationReviewForm
        context="admin"
        :idvEntryToUpdate
        @update-idv-status="updateStatusToOverridden"
        @cancel-idv-update="$emit('finished', { updated: false })"
        confirm-action-text="Yes, Pass IDV"
        cancel-action-text="Nevermind"
    />
</template>

<script setup lang="ts">
import type { IDVReviewEntry } from '@/api/interfaces/identity-verification.api';
import { IDVStatus } from '@/api/interfaces/identity-verification.api';
import { useIdentityVerificationService } from '@/services/identity-verification.service';
import { useToast } from 'primevue/usetoast';
import IdentityVerificationReviewForm from './IdentityVerificationReviewForm.vue';

const identityVerificationService = useIdentityVerificationService();
const toast = useToast();

const emit = defineEmits<{
    (event: 'finished', result: { updated: boolean }): void;
}>();

withDefaults(
    defineProps<{
        idvEntryToUpdate: IDVReviewEntry['id'] | null;
        context?: 'employee' | 'admin';
    }>(),
    { idvEntryToUpdate: null, context: 'admin' },
);

const updateStatusToOverridden = async ({ idv, reason }: { idv: IDVReviewEntry['id']; status: IDVStatus; reason: string }) => {
    try {
        await identityVerificationService.updateIdentityVerificationEntry(
            idv,
            {
                status: IDVStatus.overridden,
                reason,
            },
            'admin',
        );
    } catch (error) {
        console.error('Error updating IDV status', error);
        toast.add({ severity: 'error', summary: 'Error updating IDV status' });
    } finally {
        emit('finished', { updated: true });
    }
};
</script>

<style scoped></style>
