import { csrfToken } from '@/components/Utils/Helper';
import { createFetch } from '@vueuse/core';

function buildFetch() {
    return (url?: string) =>
        createFetch({
            baseUrl: url,
            fetchOptions: {
                headers: {
                    'X-CSRF-TOKEN': csrfToken?.value,
                },
            },
        });
}

export const useEmployeeApi = buildFetch();
export const useCompanyApi = buildFetch();
export const useClientApi = buildFetch();
