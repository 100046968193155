import { IDVDependencyTypeKeys, IDVDependencyTypes } from '@/employee-portal/pages/accounts/forms/form-payloads';
export const setIDVDependencySettings = (formInstance: HTMLFormElement, idvDependencyTypes: IDVDependencyTypes) => {
    Object.keys(idvDependencyTypes).forEach(function (type: string) {
        const { flagToSet } = idvDependencyTypes[type as IDVDependencyTypeKeys];
        const { checked } = document.getElementById(flagToSet) as HTMLInputElement;
        // set the value of the flag to the checked status of the checkbox
        const element = formInstance.elements.namedItem(flagToSet);
        if (element instanceof HTMLElement) {
            element.setAttribute('value', checked ? '1' : '0');
        }
    });
};
